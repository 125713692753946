<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel5 == 'UserHomeBandsBandMembersEdit'">
			<div class="debug">UserHomeBandsBandMembersEdit</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel5', '')"><LeftIcon /></a>
				<div class="title center">{{ active.member.name }}</div>
			</div>

			<div class="content">
				<div class="text">
					<h1>{{ $translate("UserHomeBandsBandMembersEditTitle") }}</h1>

					{{ $translate("UserHomeBandsBandMembersEditText") }}
				</div>

				<div class="warning" v-if="this.band.groups.length == 0">{{ $translate("UserHomeBandsBandMembersEditNogroups") }}</div>

				<table>
					<tr v-for="group in this.band.groups" :key="group.id">
						<td class="w32">
							<span v-if="groupstatus(group)">
								<CheckedIcon />
							</span>

							<span v-else>
								<UncheckedIcon />
							</span>
						</td>

						<td class="tal">
							{{ group.name }}
						</td>

						<td class="">
							<Toggle :value="groupstatus(group)" @change="updategroup(group)" />
						</td>
					</tr>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";

// https://github.com/vueform/toggle

import {mapState} from "vuex";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";
import Toggle from "@vueform/toggle";
import CheckedIcon from "@/components/common/icons/CheckedIcon.vue";
import UncheckedIcon from "@/components/common/icons/UncheckedIcon.vue";

export default {
	data() {
		return {
			email: "",
		};
	},

	components: {
		LeftIcon,
		Toggle,
		CheckedIcon,
		UncheckedIcon,
	},
	computed: {
		...mapState(["active", "config", "user", "band"]),
	},

	methods: {
		groupstatus(group) {
			var user = this.band.users.find((user) => user.id === this.active.member.id);

			var userGroups = user.user_groups;

			// console.log(userGroups);

			if (userGroups.some((groups) => groups.group_id === group.id && groups.state === 1)) {
				return true;
			} else {
				return false;
			}
		},

		async updategroup(group) {
			var post = {};

			post.task = "setGroup";
			post.usertoken = this.active.usertoken;
			post.bandId = this.band.id;
			post.groupId = group.id;
			post.userId = this.active.member.id;

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "groups", post)

				.then((response) => {
					this.$setActiveProperty("loading", false);

					console.log(response.data);

					this.$store.dispatch("fetchBands");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped>
.status-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.icon {
	width: 20px;
	height: 20px;
}
</style>
