<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandChecklist'">
			<div class="debug">UserHomeBandsBandChecklist</div>
			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon /></a>
				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<div class="text">
					<h1>{{ $translate("UserHomeBandsBandChecklistTitle") }}</h1>
					{{ $translate("UserHomeBandsBandChecklistText") }}
				</div>

				<draggable v-model="localChecklists" class="" item-key="checklist.id" @end="updateItems">
					<template #item="{element: checklist}">
						<div class="container-drag">
							<div class="icon order links"><OrderIcon /></div>

							<a class="button grey w100 midden mr16">{{ checklist.name }}</a>
							<div class="icon delete rechts" @click="deleteField(checklist.id)"><DeleteIcon /></div>
						</div>
					</template>
				</draggable>

				<table class="form">
					<tr>
						<td class="w100"><input v-model="fieldname" type="text" :placeholder="$translate('name')" class="mr15" /></td>
						<td class="tar ml16">
							<div class="button fr" @click="newfield()">{{ $translate("UserHomeBandsBandChecklistNew") }}</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import draggable from "vuedraggable";
import axios from "axios";

import LeftIcon from "@/components/common/icons/LeftIcon.vue";
import DeleteIcon from "@/components/common/icons/DeleteIcon.vue";
import OrderIcon from "@/components/common/icons/OrderIcon.vue";

export default {
	components: {
		draggable,
		LeftIcon,
		DeleteIcon,
		OrderIcon,
	},
	data() {
		return {
			localChecklists: ["temp"], // Lokale kopie van checklists
			fieldname: "",
		};
	},
	computed: {
		...mapState(["active", "statuses", "translate", "config", "band"]),

		checklists() {
			try {
				return JSON.parse(this.band.checklists);
			} catch (error) {
				return [];
			}
		},
	},
	methods: {
		deleteField(id) {
			this.localChecklists = this.localChecklists.filter((element) => element.id !== id);
			this.updateItems();
		},

		newfield() {
			var field = {};
			field.name = this.fieldname;
			field.id = 1;
			field.value = false;

			this.localChecklists.push(field);

			this.updateItems();

			this.fieldname = "";
		},
		updateItems() {
			var checklists = this.localChecklists.map((item, index) => ({
				...item,
				id: index + 1,
			}));

			var post = {};

			post.task = "updateChecklists";
			post.usertoken = this.active.usertoken;
			post.bandId = this.active.bandId;
			post.checklists = checklists;

			axios.post(this.config.apiserver + "bands", post)

				.then((response) => {
					if (this.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch("fetchBands");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
	watch: {
		checklists: {
			handler(newChecklists) {
				if (newChecklists) {
					this.localChecklists = [...newChecklists];
				} else {
					this.localChecklists = [];
				}
			},
			immediate: true, // Dit zorgt ervoor dat de watcher direct bij het laden wordt uitgevoerd
		},
	},
};
</script>

<style scoped>
.container-drag {
	display: flex;
	align-items: center; /* Voegt verticale centrering toe */
	width: 100%;
}

.links,
.rechts {
	flex: 0 0 auto;
}

.midden {
	flex: 1;
}
</style>
