<template>
	<div class="screen">
		<h1>
			{{ tabname }}

			<span v-if="$userIsAdmin()">
				<span v-if="groups">
					<span v-for="group in groups" v-bind:key="group.id">
						<span class="badge">{{ group.name }}</span>
					</span>
				</span>
				<span v-else>
					<span class="badge">Iedereen</span>
				</span>
			</span>
		</h1>
		<quill-editor ref="quillEditor" @ready="onEditorReady" />

		<a class="button topright" v-if="$userIsAdmin() && !this.active.isEditing" @click="this.$setActiveProperty('isEditing', true)">{{ $translate("EDIT") }}</a>

		<a class="button bottomright" v-if="$userIsAdmin() && this.active.isEditing" @click="submit">{{ $translate("SAVE") }}</a>
	</div>
</template>

<script>
import {QuillEditor} from "@vueup/vue-quill";
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {};
	},

	props: {
		tab: {
			type: Object,
			required: true,
		},
	},

	components: {QuillEditor},
	computed: {
		...mapState(["active", "gig", "config", "band"]),

		isediting() {
			return this.active.isEditing;
		},
		tabname() {
			return this.active?.tab?.name || "";
		},
		groups() {
			var groups = this.active?.tab?.groups;

			if (!groups || groups.length < 1) {
				return null;
			} else {
				return groups;
			}
		},
	},

	methods: {
		onEditorReady(editor) {
			this.quill = editor;
		},
		submit() {
			if (this.config.debug == 1) {
				console.log("updateNote");
			}

			this.$setActiveProperty("isEditing", false);

			const delta = this.quill.getContents();

			var post = {};

			post.task = "updateNote";
			post.usertoken = this.active.usertoken;
			post.gigId = this.gig.id;
			post.note = JSON.stringify(delta);
			post.tabId = this.tab.id;
			post.groups = this.tab.groups.map((group) => group.id);

			// console.log(post);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "notes", post)

				.then(() => {
					//
					this.$setActiveProperty("loading", false);
					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},
	watch: {
		isediting() {
			if (this.active.isEditing == true) {
				this.quill.enable(true);
				this.quill.getModule("toolbar").container.style.display = "block";
			} else {
				this.quill.enable(false);
				this.quill.getModule("toolbar").container.style.display = "none";
			}
		},
	},
	mounted() {
		const note = this.gig.notes.find((note) => note.tabid === this.tab.id);

		if (note) {
			const delta = JSON.parse(note.note);
			this.quill.setContents(delta);
		} else {
			this.quill.setContents([]);
		}

		this.quill.enable(false);
		this.quill.getModule("toolbar").container.style.display = "none";
	},
};
</script>

<style scoped>
.screen {
	height: calc(100dvh - 230px);
}

.down {
	fill: var(--grey-1) "";
}
</style>
