<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel2 == 'UserHomeAccount'">
			<div class="debug">UserHomeAccount</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel2', '')"><LeftIcon /></a>

				<div class="title center">{{ $translate("USERHOME_YOURACCOUNT") }}</div>
			</div>

			<div class="content">
				<div class="formgroup" :class="{editing: this.active.isEditing}">
					<div class="label">{{ $translate("USERHOMEACCOUNT_NAME") }}</div>
					<div class="inputgroup">
						<input v-model="this.active.user.name" type="text" :disabled="!this.active.isEditing" />
					</div>
				</div>

				<div class="formgroup" :class="{editing: this.active.isEditing}">
					<div class="label">{{ $translate("USERHOMEACCOUNT_LANGUAGE") }}</div>
					<div class="inputgroup" @click="this.active.isEditing && $setActiveProperty('panel3', 'UserHomeAccountLanguages')">
						<input :value="language" type="text" />
						<div class="chevron"></div>
					</div>
				</div>

				<div class="formgroup" :class="{editing: this.active.isEditing}">
					<div class="label">{{ $translate("USERHOMEACCOUNT_THEME") }}</div>
					<div class="inputgroup" @click="this.active.isEditing && $setActiveProperty('panel3', 'UserHomeAccountThemes')">
						<input :value="theme" type="text" />
						<div class="chevron"></div>
					</div>
				</div>

				<a class="button topright" v-if="!this.active.isEditing" @click="$setActiveProperty('isEditing', true)">{{ $translate("EDIT") }}</a>
				<a class="button bottomright" v-if="this.active.isEditing" @click="submit()">{{ $translate("SAVE") }}</a>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

import LeftIcon from "@/components/common/icons/LeftIcon.vue";

export default {
	data() {
		return {
			isEditing: false,
		};
	},

	components: {
		LeftIcon,
	},
	computed: {
		...mapState(["active", "config", "user"]),

		theme() {
			var theme = this.active.settings.find((item) => item.name === "theme");

			return theme.value;
		},

		language() {
			var lang = this.active.settings.find((item) => item.name === "language");

			if (lang.value == "nl") {
				return "Nederlands";
			}

			if (lang.value == "en") {
				return "English";
			}

			if (lang.value == "de") {
				return "Deutsch";
			}

			if (lang.value == "fr") {
				return "Français";
			}

			if (lang.value == "es") {
				return "Español";
			}

			return "";
		},
	},

	methods: {
		submit() {
			if (this.config.debug == 1) {
				console.log("updateUser");
			}

			this.$setActiveProperty("isEditing", false);

			let json = JSON.stringify(this.active.settings);

			var post = {};

			post.task = "updateUser";
			post.usertoken = this.active.usertoken;
			post.username = this.active.user.name;
			post.settings = json;

			console.log(post);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "users", post)

				.then(() => {
					// console.log(response.data);

					this.$setActiveProperty("loading", false);

					this.$store.dispatch("fetchUser");
					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},

	watch: {
		"user.updated_at": {
			handler() {
				// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.

				var settings = JSON.parse(JSON.stringify(this.user.settings));
				this.$setActiveProperty("settings", settings);

				var user = JSON.parse(JSON.stringify(this.user));
				this.$setActiveProperty("user", user);
			},
			immediate: true,
		},
	},
};
</script>

<style scoped></style>
