<template>
	<transition name="slide-up">
		<div class="panel autoheight boxshadow" v-if="this.active.panel5 == 'UserHomeBandsBandTabsCreate'">
			<div class="debug">UserHomeBandsBandTabsCreate</div>

			<div class="header">
				<div class="title">New Tab</div>
				<a class="right" @click="$setActiveProperty('panel5', '')"><DownIcon /></a>
			</div>

			<div class="content">
				<input v-model="fieldname" type="text" placeholder="Name" class="mr15" />

				<select v-model="selectedtype" id="fruit-select">
					<option v-for="type in types" :key="type" :value="type">{{ type }}</option>
				</select>

				<div class="button fr" @click="newTab()">Save</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import DownIcon from "@/components/common/icons/DownIcon.vue";

export default {
	data() {
		return {
			selectedtype: "info",
			types: ["info", "note", "chat", "upload"],
		};
	},

	components: {
		DownIcon,
	},

	methods: {
		newTab() {
			var field = {};
			field.name = this.fieldname;
			field.id = 1;
			field.type = this.selectedtype;
			field.groups = {};

			this.active.tabs.push(field);

			this.updateItems();

			this.fieldname = "";
		},
		updateItems() {
			var tabs = this.active.tabs.map((item, index) => ({
				...item,
				id: index + 1,
			}));

			var post = {};

			post.task = "updateTabs";
			post.usertoken = this.active.usertoken;
			post.bandId = this.active.bandId;
			post.tabs = tabs;

			axios.post(this.config.apiserver + "bands", post)

				.then((response) => {
					if (this.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch("fetchBands");

					this.$setActiveProperty("panel5", "");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	computed: {
		...mapState(["active", "bands", "config"]),
	},

	watch: {},
};
</script>

<style scoped></style>
