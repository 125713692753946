<template>
	<transition name="slide-up">
		<div class="panel" v-if="this.active.panel3 == 'GigHomeContacts'">
			<div class="debug">GigHomeContacts</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel3', '')"><DownIcon /></a>
				<div class="title">{{ $translate("GIGHOMECONTACTS") }}</div>
			</div>

			<div class="content">
				<input v-model="search" type="text" :placeholder="$translate('SEARCH')" />

				<div v-for="contact in contacts" :key="contact.id" class="buttongroup">
					<div class="button grey w100 mr16">{{ contact.name }} {{ contact.email }} {{ contact.note }}</div>

					<a class="button" @click="askavailable(contact)">{{ $translate("GIGHOMEMEMBERS_CHECKAVAILABILTY") }}</a>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import DownIcon from "@/components/common/icons/DownIcon.vue";
import axios from "axios";

export default {
	data() {
		return {
			search: "",
		};
	},

	components: {
		DownIcon,
	},
	computed: {
		...mapState(["active", "user", "gig", "band", "config"]),

		contacts() {
			const contacts = Array.from(this.user.contacts);
			if (!this.search.trim()) return contacts;

			const result = contacts.filter((contact) => Object.values(contact).some((value) => String(value).toLowerCase().includes(this.search.toLowerCase())));

			return result;
		},
	},

	methods: {
		async askavailable(user) {
			var post = {};

			post.task = "inviteUser";
			post.usertoken = this.active.usertoken;
			post.email = user.email;
			post.language = this.active.language;
			post.gigId = this.gig.id;
			post.bandId = this.band.id;

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					this.$setActiveProperty("loading", false);

					console.log(response.data);

					this.$store.dispatch("fetchGigs");

					this.$setActiveProperty("panel3", "");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped></style>
