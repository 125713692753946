<template>
	<transition name="slide-down">
		<div class="panel" v-if="this.active.toppanel == 'UserHome'">
			<div class="debug">UserHome</div>

			<div class="header">
				<div class="title center">{{ translate["SETTINGS"][active.language] }}</div>
				<a class="left" @click="$setActiveProperty('panel1', '')"><LeftIcon /></a>
			</div>

			<div class="content">
				<a class="button grey w100" @click="$setActiveProperty('panel2', 'UserHomeAccount')">
					{{ $translate("USERHOME_YOURACCOUNT") }}
					<RightIcon />
				</a>

				<a class="button grey w100" @click="$setActiveProperty('panel2', 'UserHomeContacts')">
					{{ $translate("USERHOME_CONTACTS") }}
					<RightIcon />
				</a>

				<div v-for="band in sortedBands" :key="band.id">
					<div class="button grey w100" @click="handleBandClick(band.id)">
						{{ band.name }}

						<span class="badge" v-if="band.role == 'owner'">admin</span>

						<RightIcon />
					</div>
				</div>

				<div class="button bottomright" @click="$setActiveProperty('panel3', 'UserHomeBandsCreate')">{{ $translate("UserHomeNew") }}</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";

import RightIcon from "@/components/common/icons/RightIcon.vue";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";

export default {
	data() {
		return {};
	},

	components: {
		RightIcon,
		LeftIcon,
	},
	computed: {
		...mapState(["active", "translate", "user", "bands"]),

		sortedBands() {
			return this.bands.slice().sort((a, b) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			});
		},
	},

	methods: {
		handleBandClick(bandId) {
			this.$setActiveProperty("bandId", bandId);
			this.$setActiveProperty("panel3", "UserHomeBandsBand");
		},
	},

	watch: {},
};
</script>

<style scoped>
.bottomright {
	bottom: 50px;
}
</style>
