<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel5 == 'UserHomeBandsBandTabsEditInfo'">
			<div class="debug">UserHomeBandsBandTabsEditInfo</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel5', '')"><LeftIcon /></a>
				<div class="title center">Edit tabblad: {{ active.tab.name }}</div>
			</div>

			<div class="content">
				<div class="section">
					<h2>Welke velden wil je laten zien?</h2>

					<draggable v-model="localInfos" class="" item-key="info.id" @end="updateItems">
						<template #item="{element: info}">
							<div class="container">
								<OrderIcon />

								<a class="button grey w100 midden">{{ info.name }}</a>
								<div class="icon delete rechts"></div>

								<DeleteIcon @click="deleteField(info.id)" />
							</div>
						</template>
					</draggable>

					<table class="form">
						<tr>
							<td class="w100"><input v-model="fieldname" type="text" placeholder="Fieldname" class="mr15" /></td>
							<td class="tar ml16"><div class="button" @click="newfield()">Add field</div></td>
						</tr>
					</table>
				</div>

				<div class="section">
					<h2>Wie heeft er toegang?</h2>

					<table>
						<tr>
							<td class="w32 mr8">
								<span v-if="everybody()">
									<CheckedIcon />
								</span>

								<span v-else>
									<UncheckedIcon />
								</span>
							</td>

							<td>Iedereen</td>
						</tr>

						<tr v-for="group in this.band.groups" :key="group.id">
							<td class="w32 mr8">
								<span v-if="tabstatus(group)">
									<CheckedIcon />
								</span>

								<span v-else>
									<UncheckedIcon />
								</span>
							</td>

							<td class="tal">
								{{ group.name }}
							</td>

							<td class="tar">
								<Toggle :value="tabstatus(group)" @change="updatetab(group)" />
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";
import Toggle from "@vueform/toggle";
import draggable from "vuedraggable";
import CheckedIcon from "@/components/common/icons/CheckedIcon.vue";
import UncheckedIcon from "@/components/common/icons/UncheckedIcon.vue";
import DeleteIcon from "@/components/common/icons/DeleteIcon.vue";
import OrderIcon from "@/components/common/icons/OrderIcon.vue";

export default {
	data() {
		return {
			localInfos: [], // Lokale kopie van infos
			fieldname: "",
		};
	},

	components: {
		LeftIcon,
		Toggle,
		UncheckedIcon,
		CheckedIcon,
		draggable,
		DeleteIcon,
		OrderIcon,
	},

	methods: {
		everybody() {
			const tab = this.active.tabs.find((t) => t.id === this.active.tab.id);

			return tab.groups.length === 0;
		},

		tabstatus(group) {
			const tab = this.active.tabs.find((t) => t.id === this.active.tab.id);

			if (tab) {
				return tab.groups.some((g) => g.id === group.id);
			}

			return false;
		},

		updatetab(group) {
			const tab = this.active.tabs.find((t) => t.id === this.active.tab.id);

			if (tab) {
				const groupIndex = tab.groups.findIndex((g) => g.id === group.id);

				if (groupIndex > -1) {
					tab.groups.splice(groupIndex, 1);
				} else {
					tab.groups.push({id: group.id, name: group.name});
				}
			}

			var tabs = [...this.active.tabs];

			this.$setActiveProperty("tabs", tabs);

			tabs = this.active.tabs.map((item, index) => ({
				...item,
				id: index + 1,
			}));

			var post = {};

			post.task = "updateTabs";
			post.usertoken = this.active.usertoken;
			post.bandId = this.active.bandId;
			post.tabs = tabs;

			axios.post(this.config.apiserver + "bands", post)

				.then((response) => {
					if (this.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch("fetchBands");
				})
				.catch((error) => {
					console.error(error);
				});
		},

		deleteField(id) {
			this.localInfos = this.localInfos.filter((element) => element.id !== id);
			this.updateItems();
		},

		newfield() {
			var field = {};
			field.name = this.fieldname;
			field.id = 1;
			field.value = "";
			field.tabId = this.active.tab.id;

			this.localInfos.push(field);

			this.updateItems();

			this.fieldname = "";
		},
		updateItems() {
			var infos = this.localInfos.map((item, index) => ({
				...item,
				id: index + 1,
			}));

			var andereInfos = JSON.parse(this.band.infos);
			var filteredInfos = andereInfos.filter((item) => item.tabId !== this.active.tab.id);

			infos = [...infos, ...filteredInfos];

			var post = {};

			post.task = "updateInfos";
			post.usertoken = this.active.usertoken;
			post.bandId = this.active.bandId;
			post.infos = infos;

			axios.post(this.config.apiserver + "bands", post)

				.then((response) => {
					if (this.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch("fetchBands");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	computed: {
		...mapState(["active", "bands", "config", "band"]),

		infos() {
			try {
				var infos = JSON.parse(this.band.infos);

				return infos.filter((info) => info.tabId === this.active.tab.id);
			} catch (error) {
				return [];
			}
		},
	},

	watch: {
		infos: {
			handler(newInfos) {
				if (newInfos) {
					this.localInfos = [...newInfos];
				} else {
					this.localInfos = [];
				}
			},
			immediate: true, // Dit zorgt ervoor dat de watcher direct bij het laden wordt uitgevoerd
		},
	},
};
</script>

<style scoped>
.container {
	display: flex;
	align-items: center; /* Voegt verticale centrering toe */
}

.links,
.rechts {
	flex: 0 0 auto;
}

.icon.order {
	width: 24px;
	height: 24px;
	stroke: #fff;
	position: relative;
	top: -4px;
	margin-right: 8px;
}

.icon.delete {
	width: 24px;
	height: 24px;
	position: relative;
	top: -4px;
}

.midden {
	flex: 1;
}
</style>
