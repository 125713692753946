<template>
	<div class="screen" v-if="gigsbyday">
		<div class="week">
			<div class="left">
				<div class="day" v-for="(day, index) in weekDays.slice(0, 4)" :key="index" @mouseover="handleDayClick(day.day, day.month)" @touchstart="handleDayClick(day.day, day.month)">
					<div class="small bold uppercase">{{ day.formatted }}</div>

					<div class="gigs">
						<div class="personal">
							<div v-for="gig in day.gigs" :key="gig.id">
								<div v-if="gig.shared == false" @click="handleGigClick(gig)">
									<CalenderWeekGig :gig="gig" />
								</div>
							</div>
						</div>

						<div class="shared">
							<div v-for="gig in day.gigs" :key="gig.id">
								<div v-if="gig.shared == true" @click="handleGigClick(gig)">
									<CalenderWeekGig :gig="gig" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- day-->
			</div>
			<!--left -->

			<div class="right">
				<div class="day" v-for="(day, index) in weekDays.slice(4)" :key="index" @mouseover="handleDayClick(day.day, day.month)" @touchstart="handleDayClick(day.day, day.month)">
					<div class="small bold uppercase">{{ day.formatted }}</div>

					<div class="gigs">
						<div class="personal">
							<div v-for="gig in day.gigs" :key="gig.id">
								<div v-if="gig.shared == false" @click="handleGigClick(gig)">
									<CalenderWeekGig :gig="gig" />
								</div>
							</div>
						</div>

						<div class="shared">
							<div v-for="gig in day.gigs" :key="gig.id">
								<div v-if="gig.shared == true" @click="handleGigClick(gig)">
									<CalenderWeekGig :gig="gig" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="day"></div>

				<!-- day-->
			</div>
			<!--right -->
		</div>
	</div>
</template>

<script>
import CalenderWeekGig from "@/components/calender/CalenderWeekGig.vue";

import {mapState} from "vuex";

export default {
	props: {
		weekNumber: Number,
	},
	components: {
		CalenderWeekGig,
	},

	computed: {
		...mapState(["active", "gigsbyday", "translate", "bands", "user", "gig"]),

		weekDays() {
			const weekDays = [];

			const startDate = this.getFirstDateOfWeek(this.weekNumber, this.active.currentYear);

			// Genereer de dagen van de week vanaf de startdatum
			for (let i = 0; i < 7; i++) {
				const date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i);

				const formatted = this.$formatDate(date, "short");

				weekDays.push({
					day: date.getDate(),
					month: date.getMonth() + 1,
					year: date.getFullYear(),
					formatted: formatted,
				});
			}

			weekDays.forEach((dayObject) => {
				const formattedMonth = dayObject.month.toString().padStart(2, "0");
				const formattedDay = dayObject.day.toString().padStart(2, "0");
				const dayKey = `${this.active.currentYear}-${formattedMonth}-${formattedDay}`;

				if (this.gigsbyday[dayKey]) {
					dayObject.gigs = this.gigsbyday[dayKey].sort((a, b) => {
						// Bepalen of de gig het type 'band' is
						const isABand = a.gig && a.gig.sort === "band";
						const isBBand = b.gig && b.gig.sort === "band";

						// Zet bands bovenaan
						if (isABand && !isBBand) {
							return -1; // a heeft voorrang omdat het een 'band' is en b niet
						} else if (!isABand && isBBand) {
							return 1; // b heeft voorrang omdat het een 'band' is en a niet
						}

						// Als beide 'band' zijn of beide niet 'band' zijn, sorteer op starttime
						if (a.starttime && b.starttime) {
							// Aangenomen dat starttime een numerieke waarde is of een datum/tijd string die direct kan worden vergeleken
							return a.starttime < b.starttime ? -1 : a.starttime > b.starttime ? 1 : 0;
						}

						// Als starttime ontbreekt in een van de gigs, behandel de aanwezigheid als hoger sorteer gewicht
						if (!a.starttime) {
							return 1; // b heeft voorrang omdat a geen starttime heeft
						} else if (!b.starttime) {
							return -1; // a heeft voorrang omdat b geen starttime heeft
						}

						// Als beide geen starttime hebben, behouden ze hun relatieve volgorde
						return 0;
					});
				}
			});

			return weekDays;
		},
	},

	methods: {
		handleGigClick(gig) {
			this.$setActiveProperty("gigId", gig.id);

			if (gig.sort == "appointment") {
				this.$setActiveProperty("panel1", "AppointmentHome");
			}

			if (gig.sort == "band") {
				this.$setActiveProperty("panel1", "GigHome");
			}
		},

		handleDayClick(day, month) {
			this.$setActiveProperty("gotoDay", day);
			this.$setActiveProperty("gotoMonth", month);
		},

		getFirstDateOfWeek(weekNumber, year) {
			const januaryFirst = new Date(year, 0, 1);
			const daysOffset = (weekNumber - 1) * 7;

			// Vind de eerste dag van het jaar dat maandag is
			let firstMonday = januaryFirst;
			while (firstMonday.getDay() !== 1) {
				firstMonday.setDate(firstMonday.getDate() + 1);
			}

			// Bereken de startdatum van de opgegeven week
			const startDate = new Date(firstMonday);
			startDate.setDate(firstMonday.getDate() + daysOffset);

			return startDate;
		},
		getmonth(month) {
			if (month == 1) {
				return this.translate.JAN_SHORT[this.active.language];
			}
			if (month == 2) {
				return this.translate.FEB_SHORT[this.active.language];
			}
			if (month == 3) {
				return this.translate.MAR_SHORT[this.active.language];
			}
			if (month == 4) {
				return this.translate.APR_SHORT[this.active.language];
			}
			if (month == 5) {
				return this.translate.MAY_SHORT[this.active.language];
			}
			if (month == 6) {
				return this.translate.JUN_SHORT[this.active.language];
			}
			if (month == 7) {
				return this.translate.JUL_SHORT[this.active.language];
			}
			if (month == 8) {
				return this.translate.AUG_SHORT[this.active.language];
			}
			if (month == 9) {
				return this.translate.SEP_SHORT[this.active.language];
			}
			if (month == 10) {
				return this.translate.OCT_SHORT[this.active.language];
			}
			if (month == 11) {
				return this.translate.NOV_SHORT[this.active.language];
			}
			if (month == 12) {
				return this.translate.DEC_SHORT[this.active.language];
			}

			return month;
		},
		getmember(gig) {
			var member = null;
			try {
				member = gig.user.email;
			} catch (error) {
				member = null;
			}

			if (member == this.user.email) {
				return "";
			} else {
				return member + ", ";
			}
		},
		getcolor(gig) {
			return gig.id % 10;
		},
	},
};
</script>

<style scoped>
.screen {
	padding: 8px;
}

.week {
	display: flex;
	justify-content: space-between;
	height: 100%;
}

.left {
	padding-right: 4px;
}

.right {
	padding-left: 4px;
}

.left,
.right {
	width: 50%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

.day {
	display: block;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
	clear: both;
	height: calc(100% / 4);
	max-height: calc(100% / 4);
	box-sizing: border-box;
	margin-bottom: 8px;
	border-bottom: 2px solid var(--grey-2);
	position: relative;
}

.shared {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
</style>
