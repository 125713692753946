<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandGroups'">
			<div class="debug">UserHomeBandsBandGroups</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon /></a>
				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<div class="text">
					<h1>{{ $translate("UserHomeBandsBandGroupsTitle") }}</h1>

					{{ $translate("UserHomeBandsBandGroupsText") }}
				</div>

				<span v-for="group in this.band.groups" :key="group.id">
					<a class="button grey w100">{{ group.name }}</a>
				</span>

				<div class="button bottomright" @click="$setActiveProperty('panel5', 'UserHomeBandsBandGroupsCreate')">{{ $translate("UserHomeBandsBandGroupsAddGroup") }}</div>
			</div>
		</div>
	</transition>
</template>

<script>
import LeftIcon from "@/components/common/icons/LeftIcon.vue";

import {mapState} from "vuex";

export default {
	data() {
		return {};
	},

	components: {
		LeftIcon,
	},
	computed: {
		...mapState(["active", "translate", "band"]),
	},

	methods: {},

	watch: {},
};
</script>

<style scoped></style>
