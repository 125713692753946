<template>


	<div v-if="state=='form'" class="login centercenter">


		<div class="centertext">
			<h1 class="white">Login</h1>

			<input v-model="email" type="text" class="colorblack tac" placeholder="Voer uw e-mailadres in"><br><br>
			<button class="button" @click="submitEmail">Verstuur</button>


		</div>



	</div>


	<div v-if="state=='loading'" class="login centercenter">

		<div class="loader" >

			<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

		</div>

	</div>


	<div v-if="state=='mailsend'" class="login centercenter">


		<div>
			Je kan inloggen met de link in je mail.
		</div>


			


	</div>






</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
name: 'EmailInput',
data() {
	return {
		email: '',
		state: 'form',
	};
},
computed:{

...mapState(['config']),


},
methods: {

	async submitEmail() {

		this.state = 'loading';

		var post = {};

		post.task = 'send_login_link';
		post.email = this.email;

		if (window.location.port){

			post.returnurl = 'http://' + window.location.hostname + ':' + window.location.port;

		} else {
			
			
			post.returnurl = window.location.hostname;
		}

		


		console.log(post);

		axios.post(this.config.apiserver + 'login', post )

		.then(response => {




			console.log(response.data);
			this.state = 'mailsend';

		})
		.catch(error => {
		console.error(error);



		});
	},
},



};
</script>


<style scoped>

.login{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--blauw);
	color: #fff;
}

input{

	width: 360px!important;
	text-align: center;
}

.button{

	background: #fff;
	color: var(--blauw);
}




.lds-roller {
/* change color here */
color: #fff;
}


.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



</style>
