<template>
	<transition name="slide-up">
		<div class="panel autoheight" v-if="this.active.panel1 == 'MaxAvailableNo'">
			<div class="debug">MaxAvailableNo</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel1', '')"><DownIcon /></a>
				<div class="title">{{ $translate("AVAILABLE_NO") }}</div>
			</div>

			<div class="content centertext">
				<br />

				<textarea v-model="note" :placeholder="$translate('AVAILABLE_NO')"></textarea>
				<br />
				Je kan je beschikbaarheid niet meer wijzigen.
				<br />
				<br />
				<a class="button" @click="submit()">Ik ben niet beschikbaar</a>
				<br />
			</div>
		</div>
		<!--panel-->
	</transition>
</template>

<script>
import DownIcon from "@/components/common/icons/DownIcon.vue";

import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {
			note: "",
		};
	},

	components: {
		DownIcon,
	},
	computed: {
		...mapState(["active", "gig", "translate", "config", "available"]),
	},

	methods: {
		submit() {
			if (this.config.debug == 1) {
				console.log("updateAvailable");
			}

			this.isEditing = false;

			var post = {};

			post.task = "updateAvailable";
			post.hash = this.available.hash;
			post.status = "no";
			post.note = this.note;

			console.log(post);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "gigs", post)

				.then((response) => {
					console.log(response.data);

					this.$setActiveProperty("loading", false);

					this.$setActiveProperty("panel1", "");

					this.$store.dispatch("fetchAvailable");

					this.note = "";
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},

	watch: {},
};
</script>

<style scoped>
textarea {
	width: 300px;
	height: 100px;
	margin: 10px 0;
}
</style>
