<template>
	<transition name="slide-up">
		<div class="panel autoheight boxshadow" v-if="this.active.panel3 == 'UserHomeAccountThemes'">
			<div class="debug">UserHomeAccountLanguages</div>

			<div class="header">
				<div class="title">Set theme</div>
				<a class="right" @click="$setActiveProperty('panel3', '')"><DownIcon /></a>
			</div>

			<div class="content">
				<a @click="settheme('dark')" class="button grey w100">Dark</a>
				<a @click="settheme('light')" class="button grey w100">Light</a>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import DownIcon from "@/components/common/icons/DownIcon.vue";

export default {
	data() {
		return {
			status: "",
		};
	},

	components: {
		DownIcon,
	},

	methods: {
		settheme(theme) {
			this.$setActiveSettings("theme", theme);

			this.$setActiveProperty("panel3", "");
		},
	},

	computed: {
		...mapState(["active", "bands", "config"]),
	},

	watch: {},
};
</script>

<style scoped></style>
