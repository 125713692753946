<template>
	<transition name="slide-down">
		<div class="panel top" v-if="this.active.toppanel == 'NotificationsPanel'">
			<div class="header">
				<div class="title center">{{ translate["NOTIFICATIONS"][active.language] }}</div>
			</div>
			<div class="debug">Notifications</div>

			<div v-for="notification in this.notifications" :key="notification.id" class="notification">
				<!-- {{ notification.message }} -->

				<div v-if="notification.message == 'USERSTATUSADDED'" @click="handleNotificationClick(notification)" :class="{seen: notification.id <= lastnotificationid}">
					<span class="strong">{{ notification.user.name }}</span>
					{{ $translate(notification.message) }}
					<span class="strong">{{ notification.gig.band.name }}</span>
					&nbsp;

					<span class="strong">{{ formatdate(notification.gig.start) }}</span>
					&nbsp;

					<span class="time">&nbsp;{{ $timedifference(notification) }}</span>
				</div>

				<div v-if="notification.message == 'NOTECHANGED'" @click="handleNotificationClick(notification)" :class="{seen: notification.id <= lastnotificationid}">
					<span class="strong">{{ notification.user.name }}</span>
					{{ $translate(notification.message) }}
					<span class="strong">{{ notification.gig.band.name }}</span>
					&nbsp;

					<span class="strong">{{ formatdate(notification.gig.start) }}&nbsp;</span>

					<span class="time">&nbsp;{{ $timedifference(notification) }}</span>
				</div>

				<div v-if="notification.message == 'USERSTATUSCHANGED'" @click="handleNotificationClick(notification)" :class="{seen: notification.id <= lastnotificationid}">
					<span class="strong">{{ notification.user.name }}</span>

					{{ $translate("BESCHIKBAAR-" + notification.change_name) }}

					<span class="strong">{{ notification.gig.band.name }}</span>
					&nbsp;

					<span class="strong">{{ formatdate(notification.gig.start) }}&nbsp;</span>

					<span class="time">&nbsp;{{ $timedifference(notification) }}</span>
				</div>

				<div v-if="notification.message == 'NEWUPLOAD'" @click="handleNotificationClick(notification)" :class="{seen: notification.id <= lastnotificationid}">
					<span class="strong">{{ notification.user.name }}</span>
					{{ $translate(notification.message) }}
					<span class="strong">{{ notification.change_name }}</span>
					{{ $translate("NOTIFICATION_IN") }}
					<span class="strong">{{ notification.gig.band.name }}</span>
					&nbsp;

					<span class="strong">{{ formatdate(notification.gig.start) }}&nbsp;</span>

					<span class="time">&nbsp;{{ $timedifference(notification) }}</span>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {
			name: "",
		};
	},

	components: {},
	computed: {
		...mapState(["active", "translate", "notifications", "user", "config"]),

		panel() {
			return this.active.NotificationsPanel;
		},
		lastnotificationid() {
			return this.user.lastnotificationid;
		},
	},

	methods: {
		handleNotificationClick(notification) {
			this.$setActiveProperty("gigId", notification.gig_id);
			this.$setActiveProperty("NotificationsPanel", false);

			this.$setActiveProperty("panel1", "GigHome");
		},
		formatdate(inputDate) {
			const date = new Date(inputDate); // Maak een nieuw Date object van de string
			const options = {day: "numeric", month: "long", year: "numeric"}; // Definieer de gewenste opmaak

			const formattedDate = new Intl.DateTimeFormat("nl-NL", options).format(date);

			return formattedDate;
		},

		setLastnotificationid(id) {
			if (this.config.debug == 1) {
				console.log("updateUser");
			}

			var post = {};

			post.task = "updateUser";
			post.usertoken = this.user.usertoken;
			post.lastnotificationid = id;
			//
			// console.log(post);

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					console.log(response.data);

					this.$store.dispatch("fetchUser");
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},

	watch: {
		panel(newValue) {
			if (newValue == true) {
				this.$store.dispatch("fetchNotifications");
			} else {
				const notificationsArray = Object.values(this.notifications);

				const highestId = notificationsArray.reduce((max, notification) => {
					return notification.id > max ? notification.id : max;
				}, 0);

				console.log(highestId);

				this.setLastnotificationid(highestId);
			}
		},
	},
};
</script>

<style scoped>
.notification {
	background: var(--grey-1);
	padding: 16px 16px;
	margin-bottom: 8px;
	border-radius: var(--border-radius-small);
	cursor: pointer;
}

.strong {
	font-weight: 700;
	white-space: nowrap;
}

.seen {
	opacity: 0.5;
}
</style>
