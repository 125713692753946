<template>
	<transition name="slide-right" v-if="band">
		<div class="panel" v-if="this.active.panel3 == 'UserHomeBandsBand'">
			<div class="debug">userhomebandsband</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel3', '')"><LeftIcon /></a>

				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<a class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandInfo')">
					{{ $translate("USERHOMEBANDSBAND_INFO") }}
					<RightIcon />
				</a>

				<a class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandSettings')">
					{{ $translate("USERHOMEBANDSBAND_SETTINGS") }}
					<RightIcon />
				</a>

				<a v-if="$userIsAdmin()" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandAdminsettings')">
					{{ $translate("USERHOMEBANDSBAND_SETTINGS") }}
					<span class="badge">{{ $translate("ADMIN") }}</span>
					<RightIcon />
				</a>

				<a v-if="$userIsAdmin()" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandGroups')">
					{{ $translate("USERHOMEBANDSBAND_GROUPS") }}
					<span class="badge">{{ $translate("ADMIN") }}</span>
					<RightIcon />
				</a>

				<a v-if="$userIsAdmin()" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandMembers')">
					{{ $translate("USERHOMEBANDSBAND_MEMBERS") }}
					<span class="badge">{{ $translate("ADMIN") }}</span>
					<RightIcon />
				</a>

				<a v-if="$userIsAdmin()" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandStatuses')">
					{{ $translate("USERHOMEBANDSBAND_STATUSSES") }}
					<span class="badge">{{ $translate("ADMIN") }}</span>
					<RightIcon />
				</a>

				<a v-if="$userIsAdmin()" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandTypes')">
					{{ $translate("USERHOMEBANDSBAND_TYPES") }}
					<span class="badge">{{ $translate("ADMIN") }}</span>
					<RightIcon />
				</a>

				<a v-if="$userIsAdmin()" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandChecklist')">
					{{ $translate("USERHOMEBANDSBAND_CHECKLIST") }}
					<span class="badge">{{ $translate("ADMIN") }}</span>
					<RightIcon />
				</a>

				<a v-if="$userIsAdmin()" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandTabs')">
					{{ $translate("USERHOMEBANDSBAND_TABS") }}
					<span class="badge">{{ $translate("ADMIN") }}</span>
					<RightIcon />
				</a>
			</div>
		</div>
	</transition>
</template>

<script>
import LeftIcon from "@/components/common/icons/LeftIcon.vue";
import RightIcon from "@/components/common/icons/RightIcon.vue";

import {mapState} from "vuex";

export default {
	data() {
		return {};
	},

	components: {
		LeftIcon,
		RightIcon,
	},

	methods: {},

	computed: {
		...mapState(["active", "band", "translate"]),
	},

	watch: {},
};
</script>

<style scoped>
.swiper-slide {
	font-size: 18px;
	height: calc(100vh - 112px);
	width: 100vw;
	box-sizing: border-box;
	position: relative;
}
</style>
