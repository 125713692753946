<template>
	<transition name="slide-up">
		<div class="panel autoheight boxshadow" v-if="this.active.panel3 == 'UserHomeContactsCreate'">
			<div class="debug">UserHomeContactsCreate</div>

			<div class="header">
				<div class="title">{{ $translate("NEWCONTACT") }}</div>
				<a class="right" @click="$setActiveProperty('panel3', '')"><DownIcon /></a>
			</div>

			<div class="content">
				<table class="form">
					<input v-model="name" type="text" :placeholder="$translate('NAME')" />
					<input v-model="email" type="text" :placeholder="$translate('EMAIL')" />

					<input v-model="note" type="text" :placeholder="$translate('NOTE')" />

					<a class="button fr" @click="submit">{{ $translate("SAVE") }}</a>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
import DownIcon from "@/components/common/icons/DownIcon.vue";
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {
			email: "",
			name: "",
			note: "",
		};
	},

	components: {
		DownIcon,
	},
	computed: {
		...mapState(["active", "translate", "config"]),
	},

	methods: {
		async submit() {
			var post = {};

			post.task = "createContact";
			post.usertoken = this.active.usertoken;
			post.name = this.name;
			post.note = this.note;
			post.email = this.email;

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					console.log(response.data);
					this.$store.dispatch("fetchUser");
					this.$setActiveProperty("panel3", "");
					// this.group = "";
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped></style>
