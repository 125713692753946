<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandSettings'">
			<div class="debug">UserHomeBandsBandSettings</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon /></a>
				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<div class="text">
					<h1>{{ $translate("UserHomeBandsBandSettingsTitle") }}</h1>
				</div>

				<table class="form">
					<tr>
						<td v-if="read == false"><UncheckedIcon /></td>
						<td v-if="read == true"><CheckedIcon /></td>
						<td>{{ $translate("UserHomeBandsBandSettingsReadAgenda") }}</td>
						<td align="right"><Toggle v-model="read" /></td>
					</tr>

					<tr>
						<td v-if="write == false"><UncheckedIcon /></td>
						<td v-if="write == true"><CheckedIcon /></td>
						<td>{{ $translate("UserHomeBandsBandSettingsWriteAgenda") }}</td>
						<td align="right"><Toggle v-model="write" /></td>
					</tr>
				</table>

				{{}}
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";
import Toggle from "@vueform/toggle";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";
import CheckedIcon from "@/components/common/icons/CheckedIcon.vue";
import UncheckedIcon from "@/components/common/icons/UncheckedIcon.vue";

// https://github.com/vueform/toggle

import {mapState} from "vuex";

export default {
	data() {
		return {
			read: false,
			write: false,
		};
	},

	components: {
		Toggle,
		LeftIcon,
		CheckedIcon,
		UncheckedIcon,
	},
	computed: {
		...mapState(["active", "config", "user", "band"]),

		userChanges() {
			return this.user;
		},
	},

	methods: {
		async submit() {
			var post = {};

			post.task = "setPermissions";
			post.usertoken = this.active.usertoken;
			post.type = "gigs";
			post.bandId = this.band.id;
			post.write = this.write;
			post.read = this.read;

			console.log(post);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "users", post)

				.then(() => {
					this.$setActiveProperty("loading", false);

					// console.log(response.data);

					this.$store.dispatch("fetchUser");
				})
				.catch((error) => {
					console.error(error);
				});
		},

		setpermissions() {
			// De permissions zitten in het user object, als de user geupdates wordt, of de band wisselt, de permissions opnieuw laden.

			try {
				const permission = this.user.permissions.find((permission) => permission.band_id === this.band.id);

				if (permission.write == 1) {
					this.write = true;
				} else {
					this.write = false;
				}

				if (permission.read == 1) {
					this.read = true;
				} else {
					this.read = false;
				}
			} catch (error) {
				return false;
			}
		},
	},

	watch: {
		write() {
			this.submit();
		},
		read() {
			this.submit();
		},

		"user.permissions": {
			handler() {
				if (this.active.panel4 == "UserHomeBandsBandSettings") {
					this.setpermissions();
				}
			},
			deep: true,
			immediate: true,
		},
		"band.id": {
			handler() {
				if (this.active.panel4 == "UserHomeBandsBandSettings") {
					this.setpermissions();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style scoped></style>
