export default {
	setActiveProperty(state, {property, value}) {
		state.active[property] = value;
	},

	setActiveSettings(state, data) {
		state.active.settings = data;
	},

	UPDATE_FETCH_STATUS(state, {dataset, result}) {
		state.fetched.push({dataset, result});
	},

	SET_GIG(state, data) {
		state.gig = data;
	},

	SET_AVAILABLE(state, data) {
		state.available = data;
	},

	SET_GIGS(state, data) {
		state.gigs = data;
	},
	SET_NOTIFICATIONS(state, data) {
		state.notifications = data;
	},

	SET_TIERS(state, data) {
		state.tiers = data;
	},

	SET_GIGSBYDAY(state, data) {
		state.gigsbyday = data;
	},

	SET_GIGSBYWEEK(state, data) {
		state.gigsbyweek = data;
	},

	SET_TRANSLATIONS(state, data) {
		state.translate = data;
	},

	SET_BANDS(state, data) {
		state.bands = data;
	},

	SET_BAND(state, data) {
		state.band = data;
	},

	SET_ISADMIN(state, data) {
		state.active.isadmin = data;
	},

	SET_USER(state, data) {
		state.user = data;
	},

	SET_ACTIVE_LOADING(state, data) {
		state.active.loading = data;
	},
	SET_BANDFILTER(state, data) {
		state.active.filterBandIds = data;
	},
	SET_ACTIVE_NUMBEROFFILTEREDGIGS(state, data) {
		state.active.filterNumberOfGigsLeft = data;
	},

	SET_GIGCHATS(state, {gigId, chats}) {
		if (state.gigchats[gigId]) {
			state.gigchats[gigId] = [...state.gigchats[gigId], ...chats];
		} else {
			state.gigchats[gigId] = chats;
		}
	},
	SET_ACTIVE_BANDID(state, data) {
		state.active.bandId = data;
	},
	SET_ACTIVE_LANGUAGE(state, data) {
		state.active.language = data;
	},
};
