import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Pusher from "pusher-js";

import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "./assets/normalize.css";
import "./assets/bootstrap-grid.css";
import "./assets/global.css";

const app = createApp(App);

// Initialiseer Pusher
const pusher = new Pusher("1496a9a186a4f6e7d9a1", {
	cluster: "eu",
});

// Voeg Pusher toe aan de globale eigenschappen van je Vue-applicatie
app.config.globalProperties.$pusher = pusher;

app.config.globalProperties.$setActiveProperty = function (property, value) {
	store.commit("setActiveProperty", {property, value});
};

app.config.globalProperties.$setActiveSettings = function (property, value) {
	var setting = {name: property, value: value};

	var settings = this.active.settings;

	var index = settings.findIndex((item) => item.name === property);

	if (index !== -1) {
		settings[index] = {...settings[index], ...setting};
	} else {
		settings.push(setting);
	}

	store.commit("setActiveSettings", settings);
};

app.config.globalProperties.$getTierAcces = function (module) {
	const tier = store.state.tiers.find((item) => item.module === module);
	const usertier = store.state.user.tier;

	const acces = tier[`tier${usertier}`];

	if (acces == 1) {
		return true;
	} else {
		return false;
	}
};

app.config.globalProperties.$userHasTier = function (module) {
	const tier = store.state.tiers.find((item) => item.module === module);
	const usertier = store.state.user.tier;

	const acces = tier[`tier${usertier}`];

	if (acces == 1) {
		return true;
	} else {
		return false;
	}
};

app.config.globalProperties.$userIsAdmin = function () {
	if (store.state.band.role == "owner") {
		return true;
	} else {
		return false;
	}
};

app.config.globalProperties.$formatDate = function (date, format) {
	var lang = store.state.active.language;

	var options = "";

	if (format == "long") {
		options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
	} else {
		options = {weekday: "short", month: "long", day: "numeric"};
	}

	// Formatteer de datum op basis van de meegegeven taal
	const formattedDate = new Intl.DateTimeFormat(lang, options).format(date);

	// Geef de geformatteerde datum en de taak terug
	return formattedDate;
};

app.config.globalProperties.$translate = function (string) {
	const language = store.state.active.language;

	let translation;

	try {
		translation = store.state.translate[string][language];
	} catch (error) {
		translation = "[" + string + "]";
	}

	return translation;
};

app.config.globalProperties.$timedifference = function (object) {
	const language = store.state.active.language;

	let time = object.updated_at || object.created_at;
	// Controleer of de tijdsbron beschikbaar is
	if (!time) {
		return "Geen datum beschikbaar"; // Teruggeven als er geen geldige tijd beschikbaar is
	}

	// Zorg ervoor dat het formaat van de tijd geschikt is voor de Date constructor
	time = time.replace(" ", "T"); // Vervang de spatie tussen datum en tijd door "T"

	const nu = new Date(); // Huidige tijd
	const tijd = new Date(time); // Tijd uit de database

	// Controleer of de tijd geldig is
	if (isNaN(tijd)) {
		return "Ongeldige datum"; // Teruggeven als de datum niet goed geparsed kan worden
	}

	// Verschil in milliseconden
	const verschilInMillis = nu - tijd;

	// Verschil in minuten, uren, dagen, weken, maanden en jaren
	const verschilInMinuten = Math.floor(verschilInMillis / (1000 * 60));
	const verschilInUren = Math.floor(verschilInMillis / (1000 * 60 * 60));
	const verschilInDagen = Math.floor(verschilInMillis / (1000 * 60 * 60 * 24));
	const verschilInWeken = Math.floor(verschilInDagen / 7);
	const verschilInMaanden = Math.floor(verschilInDagen / 30.44); // Gemiddeld aantal dagen in een maand
	const verschilInJaren = Math.floor(verschilInDagen / 365.25); // Rekening houdend met schrikkeljaren

	// Minder dan een uur geleden, geef het aantal minuten terug
	if (verschilInMinuten < 60) {
		return verschilInMinuten + " " + store.state.translate["MINUTESAGO"][language];
	}

	// Minder dan een dag geleden, geef het aantal uren terug
	if (verschilInUren < 24) {
		return verschilInUren + " " + store.state.translate["HOURSAGO"][language];
	}

	// Minder dan een week geleden, geef het aantal dagen terug
	if (verschilInDagen < 7) {
		return verschilInDagen + " " + store.state.translate["DAYSAGO"][language];
	}

	// Minder dan een maand geleden, geef het aantal weken terug
	if (verschilInDagen < 30.44) {
		return verschilInWeken + " " + store.state.translate["WEEKSAGO"][language];
	}

	// Minder dan een jaar geleden, geef het aantal maanden terug
	if (verschilInDagen < 365.25) {
		return verschilInMaanden + " " + store.state.translate["MONTHSAGO"][language];
	}

	// Anders, geef het aantal jaren en maanden terug
	const resterendeMaanden = Math.floor((verschilInDagen % 365.25) / 30.44);
	return verschilInJaren + " jaar" + (resterendeMaanden > 0 ? " en " + resterendeMaanden + " " + store.state.translate["MONTHSAGO"][language] : "");
};

app.use(router).use(store).mount("#app");
