<template>
	<div class="screen">
		<h1>
			{{ tabname }}

			<span v-if="$userIsAdmin()">
				<span v-if="groups">
					<span v-for="group in groups" v-bind:key="group.id">
						<span class="badge">{{ group.name }}</span>
					</span>
				</span>
				<span v-else>
					<span class="badge">Iedereen</span>
				</span>
			</span>
		</h1>

		<span v-for="info in infos" :key="info.id">
			<div :class="['formgroup', editing]">
				<div class="label">{{ info.name }}</div>
				<div class="inputgroup">
					<div :id="`input-${info.id}`" :contenteditable="this.active.isEditing" :class="{editing: this.active.isEditing}" @input="updateInfo($event, info.id)" class="editable-content">{{ info.value }}</div>
				</div>
			</div>
		</span>

		<a class="button topright" v-if="$userIsAdmin() && !this.active.isEditing" @click="this.active.isEditing = true">{{ $translate("EDIT") }}</a>

		<a class="button bottomright" v-if="$userIsAdmin() && this.active.isEditing" @click="submit">{{ $translate("SAVE") }}</a>
	</div>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {
			localgig: null,
		};
	},
	props: {
		tab: {
			type: Object,
			required: true,
		},
	},

	components: {},
	computed: {
		...mapState(["active", "gig", "band", "config"]),

		tabname() {
			return this.active?.tab?.name || "";
		},

		editing() {
			if (this.active.isEditing) {
				return "editing";
			} else {
				return "";
			}
		},

		status() {
			if (this.active.formStatus) {
				return this.active.formStatus;
			} else {
				return this.localgig.status;
			}
		},

		type() {
			if (this.active.formType) {
				return this.active.formType;
			} else {
				return this.localgig.type;
			}
		},
		groups() {
			var groups = this.active?.tab?.groups;

			if (!groups || groups.length < 1) {
				return null;
			} else {
				return groups;
			}
		},

		infos() {
			// Door de bandinfos lopen en kijken of er en waarde is definieerd

			try {
				var infos = JSON.parse(this.band.infos);

				infos = infos.filter((obj) => obj.tabId === this.active.tab.id);
			} catch (error) {
				infos = [];
			}

			try {
				var giginfos = this.gig.infos.find((info) => info.tabid === this.tab.id);

				giginfos = JSON.parse(giginfos.info);
			} catch (error) {
				giginfos = null;
			}

			if (giginfos) {
				infos = infos.map((info) => {
					const giginfo = giginfos.find((element) => element.name === info.name);

					if (giginfo) {
						return {...info, value: giginfo.value};
					} else {
						return {...info, value: ""};
					}
				});
			}

			return infos;
		},
	},

	methods: {
		updateInfo(event, id) {
			const updatedValue = event.target.innerText;
			const info = this.infos.find((info) => info.id === id);
			if (info) {
				info.value = updatedValue;
			}
		},

		submit() {
			if (this.config.debug == 1) {
				console.log("updateInfo");
			}

			this.active.isEditing = false;

			var post = {};

			post.task = "updateInfo";
			post.usertoken = this.active.usertoken;
			post.gigId = this.gig.id;
			post.infos = JSON.stringify(this.infos);
			post.tabId = this.tab.id;
			post.groups = this.tab.groups.map((group) => group.id);

			console.log(post);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "infos", post)

				.then((response) => {
					console.log(response.data);

					this.$setActiveProperty("loading", false);
					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},

	watch: {
		"active.gigId": {
			handler() {
				// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
				this.localgig = JSON.parse(JSON.stringify(this.gig));
			},
			immediate: true,
		},
	},
};
</script>

<style scoped>
.screen {
	height: calc(100dvh - 210px);
}
</style>
