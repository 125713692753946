<template>
	<transition name="slide-up">
		<div class="panel autoheight" v-if="this.active.panel2 == 'GigHomeUploadsNew'">
			<div class="debug">GigHomeUploadNew</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel2', '')"><DownIcon /></a>
				<div class="title">{{ $translate("GIGHOMEUPLOAD_TITLE") }}</div>
			</div>

			<div class="content centertext">
				<form @submit.prevent="submit" class="buttongroup">
					<input type="text" v-model="fileName" :placeholder="$translate('GIGHOMEUPLOAD_NAME')" required />
					<input type="file" ref="fileInput" @change="handleFileChange" required />
					<button class="button" type="submit">{{ $translate("GIGHOMEUPLOAD_UPLOAD") }}</button>
				</form>
			</div>
		</div>
		<!--panel-->
	</transition>
</template>

<script>
import DownIcon from "@/components/common/icons/DownIcon.vue";

import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {
			fileName: "",
		};
	},

	components: {
		DownIcon,
	},
	computed: {
		...mapState(["active", "gig", "translate", "config", "band"]),
	},

	methods: {
		submit() {
			if (this.config.debug == 1) {
				console.log("updateUpload");
			}

			// Maak een FormData object om het bestand op te slaan
			let formData = new FormData();
			formData.append("task", "updateUpload");
			formData.append("usertoken", this.active.usertoken);
			formData.append("name", this.fileName);
			formData.append("gigId", this.gig.id);
			formData.append("upload", this.$refs.fileInput.files[0]); // Bestand ophalen van een file input
			formData.append("tabId", this.active.tab.id);
			formData.append("bandId", this.band.id);

			// Voeg alle groepen toe
			this.active.tab.groups.forEach((group) => {
				formData.append("groups[]", group.id); // Toevoegen van meerdere groepen
			});

			console.log(formData);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "uploads", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
				.then((response) => {
					console.log(response.data);
					this.$setActiveProperty("loading", false);
					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},

	watch: {},
};
</script>

<style scoped>
textarea {
	width: 300px;
	height: 100px;
	margin: 10px 0;
}
</style>
