<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandTabs'">
			<div class="debug">UserHomeBandsBandTabs</div>
			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon /></a>
				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<div class="text">
					<h1>{{ $translate("UserHomeBandsBandTabsTitle") }}</h1>

					{{ $translate("UserHomeBandsBandTabsText") }}

					<div class="needstier" v-if="!$userHasTier('customtabs')">{{ $translate("UserNeedsTier") }}</div>
				</div>

				<div v-if="$userHasTier('customtabs')">
					<draggable v-model="active.tabs" class="" item-key="tabs.id" @end="updateItems">
						<template #item="{element: tab}">
							<div class="container-drag">
								<div class="icon order links"><OrderIcon /></div>

								<a class="button grey w100 midden mr16">
									{{ tab.name }}

									<span v-if="tab.type" class="badge grey">{{ tab.type }}</span>

									<span v-if="everybody(tab)" class="badge">iedereen</span>

									<span v-for="group in tab.groups" v-bind:key="group.id" class="badge">
										{{ group.name }}
									</span>
								</a>

								<a class="button tar" @click="openpanel(tab)">
									{{ $translate("EDIT") }}
								</a>
							</div>
						</template>
					</draggable>

					<div class="button bottomright addbutton" @click="$setActiveProperty('panel5', 'UserHomeBandsBandTabsCreate')">{{ $translate("UserHomeBandsBandTabsNew") }}</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import draggable from "vuedraggable";
import axios from "axios";

import LeftIcon from "@/components/common/icons/LeftIcon.vue";
import OrderIcon from "@/components/common/icons/OrderIcon.vue";

export default {
	components: {
		draggable,
		LeftIcon,
		OrderIcon,
	},
	data() {
		return {
			fieldname: "",
		};
	},
	computed: {
		...mapState(["active", "statuses", "translate", "config", "band"]),

		tabs() {
			try {
				return JSON.parse(this.band.tabs);
			} catch (error) {
				return [];
			}
		},
	},
	methods: {
		everybody(tab) {
			return tab.groups.length === 0;
		},

		openpanel(tab) {
			this.$setActiveProperty("tab", tab);

			// console.log(tab);

			if (tab.type == "checklist") {
				this.$setActiveProperty("panel5", "UserHomeBandsBandTabsEditChecklist");
			}

			if (tab.type == "info") {
				this.$setActiveProperty("panel5", "UserHomeBandsBandTabsEditInfo");
			}

			if (tab.type == "note") {
				this.$setActiveProperty("panel5", "UserHomeBandsBandTabsEditNote");
			}

			if (tab.type == "upload") {
				this.$setActiveProperty("panel5", "UserHomeBandsBandTabsEditUpload");
			}

			if (tab.type == "chat") {
				this.$setActiveProperty("panel5", "UserHomeBandsBandTabsEditChat");
			}
		},

		deleteField(id) {
			this.localTabs = this.localTabs.filter((element) => element.id !== id);
			this.updateItems();
		},

		updateItems() {
			var tabs = this.active.tabs.map((item, index) => ({
				...item,
				id: index + 1,
			}));

			var post = {};

			post.task = "updateTabs";
			post.usertoken = this.active.usertoken;
			post.bandId = this.active.bandId;
			post.tabs = tabs;

			axios.post(this.config.apiserver + "bands", post)

				.then((response) => {
					if (this.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch("fetchBands");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
	watch: {
		tabs: {
			handler(newTabs) {
				if (newTabs) {
					this.active.tabs = [...newTabs];
				} else {
					this.active.tabs = [];
				}
			},
			immediate: true, // Dit zorgt ervoor dat de watcher direct bij het laden wordt uitgevoerd
		},
	},
};
</script>

<style scoped>
.container-drag {
	display: flex;
	align-items: center; /* Voegt verticale centrering toe */
}

.links,
.rechts {
	flex: 0 0 auto;
}

.midden {
	flex: 1;
}
</style>
