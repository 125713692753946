<template>
	<transition name="slide-up">
		<div class="panel autoheight" v-if="this.active.panel2 == 'GigHomeMembersInvite'">
			<div class="debug">GigHomeMembersInvite</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel2', '')"><DownIcon /></a>
				<div class="title">{{ $translate("GIGHOMEMEMBERTSINVITE_TITLE") }}</div>
			</div>

			<div class="content">
				<div class="w100 button grey" v-if="active.user.name">{{ active.user.name }}</div>
				<div v-else class="w100 button grey">{{ active.user.email }}</div>

				<a class="button" @click="sendemail()">{{ $translate("GIGHOMEMEMBERTSINVITE_EMAIL") }}</a>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import DownIcon from "@/components/common/icons/DownIcon.vue";

export default {
	data() {
		return {};
	},

	components: {
		DownIcon,
	},
	computed: {
		...mapState(["active", "gig", "band", "config"]),
	},

	methods: {
		async sendemail() {
			var post = {};

			post.task = "inviteUser";
			post.usertoken = this.active.usertoken;
			post.email = this.active.user.email;
			post.language = this.active.language;
			post.gigId = this.gig.id;
			post.bandId = this.band.id;

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					this.$setActiveProperty("loading", false);

					console.log(response.data);

					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped></style>
