<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel5 == 'UserHomeBandsBandTabsEditUpload'">
			<div class="debug">UserHomeBandsBandTabsEditUpload</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel5', '')"><LeftIcon /></a>
				<div class="title center">Edit tabblad: {{ active.tab.name }}</div>
			</div>

			<div class="content">
				<div class="section">
					<h2>Wie heeft er toegang?</h2>

					<table>
						<tr>
							<td class="w32 mr8">
								<span v-if="everybody()">
									<CheckedIcon />
								</span>

								<span v-else>
									<UncheckedIcon />
								</span>
							</td>

							<td>Iedereen</td>
						</tr>

						<tr v-for="group in this.band.groups" :key="group.id">
							<td class="w32 mr8">
								<span v-if="tabstatus(group)">
									<CheckedIcon />
								</span>

								<span v-else>
									<UncheckedIcon />
								</span>
							</td>

							<td class="tal">
								{{ group.name }}
							</td>

							<td class="tar">
								<Toggle :value="tabstatus(group)" @change="updatetab(group)" />
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";
import Toggle from "@vueform/toggle";

import CheckedIcon from "@/components/common/icons/CheckedIcon.vue";
import UncheckedIcon from "@/components/common/icons/UncheckedIcon.vue";

export default {
	data() {
		return {
			localChecklists: ["temp"], // Lokale kopie van checklists
			fieldname: "",
		};
	},

	components: {
		LeftIcon,
		Toggle,
		UncheckedIcon,
		CheckedIcon,
	},

	methods: {
		everybody() {
			const tab = this.active.tabs.find((t) => t.id === this.active.tab.id);

			return tab.groups.length === 0;
		},

		tabstatus(group) {
			const tab = this.active.tabs.find((t) => t.id === this.active.tab.id);

			if (tab) {
				return tab.groups.some((g) => g.id === group.id);
			}

			return false;
		},

		updatetab(group) {
			const tab = this.active.tabs.find((t) => t.id === this.active.tab.id);

			if (tab) {
				const groupIndex = tab.groups.findIndex((g) => g.id === group.id);

				if (groupIndex > -1) {
					tab.groups.splice(groupIndex, 1);
				} else {
					tab.groups.push({id: group.id, name: group.name});
				}
			}

			var tabs = [...this.active.tabs];

			this.$setActiveProperty("tabs", tabs);

			tabs = this.active.tabs.map((item, index) => ({
				...item,
				id: index + 1,
			}));

			var post = {};

			post.task = "updateTabs";
			post.usertoken = this.active.usertoken;
			post.bandId = this.active.bandId;
			post.tabs = tabs;

			axios.post(this.config.apiserver + "bands", post)

				.then((response) => {
					if (this.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch("fetchBands");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	computed: {
		...mapState(["active", "bands", "config", "band"]),
	},

	watch: {},
};
</script>

<style scoped>
.container {
	display: flex;
	align-items: center; /* Voegt verticale centrering toe */
}

.links,
.rechts {
	flex: 0 0 auto;
}

.icon.order {
	width: 24px;
	height: 24px;
	stroke: #fff;
	position: relative;
	top: -4px;
	margin-right: 8px;
}

.icon.delete {
	width: 24px;
	height: 24px;
	position: relative;
	top: -4px;
}

.midden {
	flex: 1;
}
</style>
