<template>
	<transition name="slide-up" v-if="gig">
		<div class="panel" v-if="this.active.panel1 == 'GigHome'">
			<div class="debug">GigHome</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel1', '')"><DownIcon /></a>

				<div class="title">
					<div v-if="gig.numberofdays == 1">
						<span class="uppercase">{{ gig.dateStart.date }} {{ gig.dateStart.monthNameLong }} {{ gig.dateStart.time }}-{{ gig.dateEnd.time }}</span>
						<span class="small fr"></span>
					</div>

					<div>
						<div v-if="gig.numberofdays > 1" class="nowrap">
							<span class="uppercase">{{ gig.dateStart.date }} {{ gig.dateStart.monthNameLong }} {{ gig.dateStart.time }} - {{ gig.dateEnd.date }} {{ gig.dateEnd.monthNameLong }} {{ gig.dateEnd.time }}</span>
						</div>
					</div>
				</div>
			</div>

			<div v-if="gig">
				<div class="header2">
					<div>
						<span class="bold big">
							{{ gig.band.name }}
							<span v-if="gig.userstatus == 'admin'" class="badge">admin</span>
						</span>
					</div>

					<div class="">
						<span class="big">{{ gig.venue }}, {{ gig.city }}</span>
					</div>

					<div class=" ">
						<span class="">{{ gig.type.name }}</span>
						<span class="fr">{{ gig.status.name }}</span>
					</div>
				</div>

				<div class="slidemenu-container">
					<div class="slidemenu">
						<div v-if="userGigStatus == 'invited' || userGigStatus == 'maybe'" class="item" :class="{active: this.currentslide == 'slide-answer'}">
							<div class="text" @click="gotoslide('slide-answer')">
								{{ $translate("GIGHOME_AVAILABILTY") }}
							</div>
						</div>

						<div class="item" :class="{active: this.currentslide == 'slide-gig'}">
							<div class="text" @click="gotoslide('slide-gig')">
								{{ $translate("GIGHOME_INFO") }}
							</div>
						</div>

						<div class="item" :class="{active: this.currentslide == 'slide-location'}">
							<div class="text" @click="gotoslide('slide-location')">
								{{ $translate("GIGHOME_LOCATION") }}
							</div>
						</div>

						<div class="item" :class="{active: this.currentslide == 'slide-members'}">
							<div class="text" @click="gotoslide('slide-members')">
								{{ $translate("GIGHOME_MUSICIANS") }}
							</div>
						</div>

						<div class="item" :class="{active: this.currentslide == 'slide-chat'}">
							<div class="text" @click="gotoslide('slide-chat')">
								{{ $translate("GIGHOME_CHAT") }}
							</div>
						</div>

						<div v-if="$userIsAdmin()" class="item" :class="{active: this.currentslide == 'slide-checklist'}">
							<div class="text" @click="gotoslide('slide-checklist')">
								{{ $translate("GIGHOME_CHECKLIST") }}
								<span class="number">{{ this.gig.openchecklistitems }}</span>
							</div>
						</div>

						<div class="item" :class="{active: this.currentslide == 'slide-privatenote'}">
							<div class="text" @click="gotoslide('slide-privatenote')">
								{{ $translate("GIGHOME_PRIVATENOTE") }}
							</div>
						</div>

						<div class="item" v-for="tab in tabs" v-bind:key="tab.id" :class="{active: this.currentslide == 'slide-' + tab.id}">
							<span v-if="userHasAcces(tab)">
								<div class="text" @click="gotoslide('slide-' + tab.id)">
									{{ tab.name }}
								</div>
							</span>
						</div>
					</div>
				</div>

				<swiper :onSwiper="setScreensSwiper" @slideChange="updateScreens" slidesPerView="1" spaceBetween="50" class="agenda-swiper" :initialSlide="0">
					<swiper-slide v-if="userGigStatus == 'invited' || userGigStatus == 'maybe'" id="slide-answer">
						<GigHomeAnswer />
					</swiper-slide>

					<swiper-slide id="slide-gig">
						<GigHomeGig />
					</swiper-slide>

					<swiper-slide id="slide-location">
						<GigHomeLocation />
					</swiper-slide>

					<swiper-slide id="slide-members">
						<GigHomeMembers />
					</swiper-slide>

					<swiper-slide id="slide-chat">
						<GigHomeChat />
					</swiper-slide>

					<swiper-slide v-if="$userIsAdmin()" id="slide-checklist">
						<GigHomeChecklist />
					</swiper-slide>

					<swiper-slide id="slide-privatenote">
						<GigHomePrivatenotes />
					</swiper-slide>

					<swiper-slide v-for="tab in tabs" v-bind:key="tab.id" :id="'slide-' + tab.id">
						<div v-if="userHasAcces(tab)">
							<div v-if="tab.type == 'info'">
								<GigHomeInfo :tab="tab" />
							</div>

							<div v-if="tab.type == 'note'">
								<GigHomeNote :tab="tab" />
							</div>

							<div v-if="tab.type == 'upload'">
								<GigHomeUploads :tab="tab" />
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
		<!--panel-->
	</transition>
</template>

<script>
import GigHomeAnswer from "@/components/gig/GigHomeAnswer.vue";
import GigHomeChat from "@/components/gig/GigHomeChat.vue";
import GigHomeInfo from "@/components/gig/GigHomeInfo.vue";
import GigHomeGig from "@/components/gig/GigHomeGig.vue";
import GigHomeUploads from "@/components/gig/GigHomeUploads.vue";
import GigHomeLocation from "@/components/gig/GigHomeLocation.vue";
import GigHomeMembers from "@/components/gig/GigHomeMembers.vue";
import GigHomePrivatenotes from "@/components/gig/GigHomePrivatenotes.vue";
import GigHomeChecklist from "@/components/gig/GigHomeChecklist";
import GigHomeNote from "@/components/gig/GigHomeNote";

import DownIcon from "@/components/common/icons/DownIcon.vue";

import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/css";

import {mapState} from "vuex";

export default {
	data() {
		return {
			currentslide: null,
		};
	},

	components: {
		Swiper,
		SwiperSlide,
		GigHomeLocation,
		GigHomeMembers,
		GigHomeAnswer,
		DownIcon,
		GigHomeChecklist,
		GigHomeInfo,
		GigHomePrivatenotes,
		GigHomeNote,
		GigHomeUploads,
		GigHomeGig,
		GigHomeChat,
	},
	computed: {
		...mapState(["active", "gig", "translate", "user", "band"]),

		numberofunchecked() {
			var checklists = JSON.parse(this.gig.checklists);

			if (checklists) {
				return checklists.filter((item) => item.value === false).length;
			} else {
				return 0;
			}
		},
		userGigStatus() {
			if (this.gig && this.gig.musicians) {
				const user = this.gig.musicians.find((user) => user.id === this.user.id);
				return user ? user.pivot.status : null;
			}
			return null;
		},
		tabs() {
			var tabs = JSON.parse(this.band.tabs);

			return tabs;
		},
	},

	methods: {
		userHasAcces(tab) {
			if (this.$userIsAdmin() == true) {
				return true;
			}

			var usergroups = this.user.groups ?? [];
			var tabgroups = tab.groups ?? [];

			if (tabgroups.length > 0) {
				for (let tabgroup of tabgroups) {
					if (usergroups.some((usergroup) => usergroup.id === tabgroup.id)) {
						return true;
					}
				}
				return false;
			} else {
				return true;
			}
		},
		setScreensSwiper(swiper) {
			this.screensswiper = swiper;
		},

		updateScreens(swiper) {
			// Wanneer de gebruiker met de hand swiped dan de tabs bijwerken

			const activeIndex = swiper.activeIndex;

			const activeSlide = swiper.slides[activeIndex];

			if (activeSlide) {
				this.currentslide = activeSlide.id;

				var tabid = activeSlide.id.slice(6);

				if (Number.isFinite(tabid)) {
					tabid = parseInt(tabid, 10);

					const tab = this.tabs.find((tab) => tab.id === tabid);

					this.$setActiveProperty("tab", tab);
				}
			}
		},

		gotoslide(slide) {
			this.$setActiveProperty("isEditing", false);

			this.currentslide = slide;

			var tabId = parseInt(slide.substring(6), 10);

			if (Number.isFinite(tabId)) {
				const tab = this.tabs.find((tab) => tab.id === tabId);
				this.$setActiveProperty("tab", tab);
			}

			const slideElement = document.getElementById(slide);

			if (slideElement) {
				const slideIndex = Array.from(slideElement.parentNode.children).indexOf(slideElement);
				this.screensswiper.slideTo(slideIndex);
			}
		},
		getmonth(month) {
			if (month == 1) {
				return this.translate.JAN_SHORT[this.active.language];
			}
			if (month == 2) {
				return this.translate.FEB_SHORT[this.active.language];
			}
			if (month == 3) {
				return this.translate.MAR_SHORT[this.active.language];
			}
			if (month == 4) {
				return this.translate.APR_SHORT[this.active.language];
			}
			if (month == 5) {
				return this.translate.MAY_SHORT[this.active.language];
			}
			if (month == 6) {
				return this.translate.JUN_SHORT[this.active.language];
			}
			if (month == 7) {
				return this.translate.JUL_SHORT[this.active.language];
			}
			if (month == 8) {
				return this.translate.AUG_SHORT[this.active.language];
			}
			if (month == 9) {
				return this.translate.SEP_SHORT[this.active.language];
			}
			if (month == 10) {
				return this.translate.OCT_SHORT[this.active.language];
			}
			if (month == 11) {
				return this.translate.NOV_SHORT[this.active.language];
			}
			if (month == 12) {
				return this.translate.DEC_SHORT[this.active.language];
			}

			return month;
		},
	},

	watch: {
		// Als je het panel opent, altijd op het eerste scherm beginnen.

		"active.panel1": {
			handler() {
				this.$setActiveProperty("gigScreen", 0);

				if (this.userGigStatus == "invited") {
					this.currentslide = "slide-answer";
				} else {
					this.currentslide = "slide-gig";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style scoped>
.panel .header {
	background: var(--grey-2);
	color: #fff;
	margin-bottom: 0;
}

.header2 {
	padding: 0px 18px;
	overflow: hidden;
	background: var(--grey-2);
	color: #fff;
}

.down {
	fill: #fff;
}

.numberofunchecked {
	background: var(--blauw);
	font-weight: 700;
	color: #fff;
	padding-top: 0px;
	font-size: 10px;
	line-height: 16px;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	display: inline-block;
	text-align: center;
	box-sizing: border-box;
	position: relative;
	top: -6px;
}

.swiper {
	height: calc(100vh - 180px);
	overflow: hidden;
}

.date {
	padding-top: 10px;
}

.badge {
	background: #fff;
	color: var(--blauw);
}
</style>
