<template>
	<div class="screen">
		<h1>
			{{ $translate("GIGHOMEMEMBERS_INVITED") }}
			<span v-if="$userIsAdmin()">
				<span class="badge">iedereen</span>
			</span>
		</h1>

		<div v-if="gig.musicians.length > 0" class="section">
			<div v-for="user in this.gig.musicians" :key="user.id" class="buttongroup">
				<div class="w100 button grey" v-if="user.name">
					{{ user.name }}
					<span v-if="user.pivot.note" class="remark">{{ user.pivot.note }}</span>
					<span class="time">{{ $timedifference(user.pivot) }}</span>
				</div>
				<div v-else class="w100 button grey">
					{{ user.email }}
					<span class="time">{{ $timedifference(user.pivot) }}</span>
				</div>
				<a class="button">{{ user.pivot.status }}</a>
				<a class="button" @click="sendemail(user)">Email</a>
				<a class="button" @click="sendwhatsapp(user)">Whatsapp</a>
			</div>
		</div>

		<div class="section" v-if="$userIsAdmin()">
			<div>
				<h2 class="pb16">{{ $translate("GIGHOMEMEMBERS_BANDMEMBERS") }}</h2>
				<div v-for="user in notinvited" :key="user.id" class="buttongroup">
					<div class="w100 button grey" v-if="user.name">{{ user.name }}</div>
					<div v-else class="w100 button grey">{{ user.email }}</div>
					<a class="button" @click="invite(user)">{{ $translate("GIGHOMEMEMBERS_CHECKAVAILABILTY") }}</a>
				</div>

				<!-- <textarea class="w100" v-model="text" type="text" placeholder="Additional text bij invite"></textarea> -->
			</div>

			<a class="button" @click="$setActiveProperty('panel3', 'GigHomeContacts')">Contacts</a>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
	data() {
		return {
			text: "",
		};
	},

	components: {},
	computed: {
		...mapState(["active", "band", "gig", "config", "user"]),

		notinvited() {
			try {
				return this.band.users.filter((user) => !this.gig.musicians.some((removeUser) => removeUser.email === user.email));
			} catch (error) {
				return null;
			}
		},
	},

	methods: {
		sendwhatsapp(user) {
			var text = "";

			text += "Beste ";

			if (user.name) {
				text += user.name;
			} else {
				text += user.email;
			}
			text += ", \n";
			text += this.user.name;
			text += " wilt graag je beschikbaarheid weten voor: ";
			text += this.gig.dateStart.date + " " + this.gig.dateStart.monthNameLong;
			text += " met ";
			text += this.gig.band.name;
			text += ". \n \n Geef hier je beschikbaarheid op: \n ";
			text += window.location.origin + "?available=" + user.pivot.hash;

			console.log(text);
		},

		async invite(user) {
			var post = {};

			post.task = "inviteUser";
			post.usertoken = this.active.usertoken;
			post.email = user.email;
			post.language = this.active.language;
			post.gigId = this.gig.id;
			post.bandId = this.band.id;

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					this.$setActiveProperty("loading", false);

					console.log(response.data);

					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					console.error(error);
				});
		},

		async sendemail(user) {
			var post = {};

			post.task = "sendEmail";
			post.usertoken = this.active.usertoken;
			post.email = user.email;
			post.language = this.active.language;
			post.gigId = this.gig.id;
			post.bandId = this.band.id;

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					this.$setActiveProperty("loading", false);

					console.log(response.data);

					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped>
.remark {
	background: var(--grey-25);
	padding: 2px 12px;
	border-radius: 10px;
	margin-left: 6px;
}
</style>
