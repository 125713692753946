<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandInfos'">
			<div class="debug">UserHomeBandsBandInfos</div>
			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>
				<div class="title center">{{band.name}} Fields</div>
			</div>

			<div class="content">
				<div class="title">{{$translate('UserHomeBandsBandInfo_Title')}}</div>
				<div class="text">{{$translate('UserHomeBandsBandInfo_Text')}}</div>

				<draggable v-model="localInfos" class="" item-key="info.id" @end="updateItems">
					<template #item="{element: info}">

						<div class="container">
							<OrderIcon/>

							<a class="button grey w100 midden"> {{ info.name }} </a>
							<div class="icon delete rechts" > </div>
							
							<DeleteIcon @click="deleteField(info.id)"/>
						</div>

					</template>
				</draggable>


				<div class="addbutton" placeholder="">

					<input v-model="fieldname" type="text" placeholder="Fieldname" class="mr15" />

					<div class="button fr" @click="newfield()">
						Add field
					</div>

				</div>



			</div>
		</div>
	</transition>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import axios from 'axios';

import LeftIcon from '@/components/common/icons/LeftIcon.vue';
import OrderIcon from '@/components/common/icons/OrderIcon.vue';
import DeleteIcon from '@/components/common/icons/DeleteIcon.vue';

export default {
components: {
	draggable,
	LeftIcon,
	OrderIcon,
	DeleteIcon
},
data() {
	return {
		localInfos: [], // Lokale kopie van infos
		fieldname: '',
	}
},
computed: {
	...mapState(['active', 'statuses', 'translate','config','band']),

	infos(){

		try {

			return JSON.parse(this.band.infos);
		} catch (error) {

			return [];
		}

	}
},
methods: {


	deleteField(id){


		this.localInfos = this.localInfos.filter(element => element.id !== id);
		this.updateItems();

	},


	newfield(){

		var field = {};
		field.name = this.fieldname;
		field.id = 1;
		field.value = '';

		this.localInfos.push(field);

		this.updateItems();

		this.fieldname = '';

	},
	updateItems() {

		var infos = this.localInfos.map((item, index) => ({
		...item,
		id: index + 1
		}));


		var post = {};

		post.task = 'updateInfos';
		post.usertoken = this.active.usertoken;
		post.bandId = this.active.bandId;
		post.infos = infos;


		axios.post(this.config.apiserver + 'bands', post )

		.then(response => {

			if (this.config.debug==1){
				console.log(response.data);

			}
			this.$store.dispatch('fetchBands');



		})
		.catch(error => {
		console.error(error);



		});

	},
},
watch: {

	infos: {
		handler(newInfos) {

		if (newInfos){

			this.localInfos = [...newInfos];
		} else {

			this.localInfos = [];
		}


		},
		immediate: true // Dit zorgt ervoor dat de watcher direct bij het laden wordt uitgevoerd
	}

},

}
</script>

<style scoped>
.container {
display: flex;
align-items: center; /* Voegt verticale centrering toe */
}

.links, .rechts {
  flex: 0 0 auto;
}

.midden {
  flex: 1;
}

.icon.order {
  width: 24px;
  height: 24px;
  stroke: #fff;
  position: relative;
  top: -10px;
  margin-right: 8px;
}

.icon.delete {
  width: 20px;
  height: 20px;
  stroke: #fff;
  position: relative;
  top: -10px;
}
</style>
