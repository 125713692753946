<template>
	<div class="screen">
		<h1>
			{{ tabname }}

			<span v-if="$userIsAdmin()">
				<span v-if="groups">
					<span v-for="group in groups" v-bind:key="group.id">
						<span class="badge">{{ group.name }}</span>
					</span>
				</span>
				<span v-else>
					<span class="badge">Iedereen</span>
				</span>
			</span>
		</h1>

		<div>
			<div class="buttongroup" v-for="upload in uploads" :key="upload.id">
				<a class="button grey w100">
					{{ upload.name }}
					<span class="time">{{ $timedifference(upload) }}</span>
				</a>

				<a class="button" @click="download(upload)">{{ $translate("DOWNLOAD") }}</a>

				<a v-if="$userIsAdmin()" class="button" @click="verwijder(upload)">{{ $translate("DELETE") }}</a>
			</div>
		</div>
	</div>

	<div v-if="$userIsAdmin()" class="footer">
		<a class="button bottomright" v-if="!this.active.isEditing" @click="this.$setActiveProperty('panel2', 'GigHomeUploadsNew')">{{ $translate("GIGHOMEUPLOADS_NEW") }}</a>
	</div>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {};
	},

	props: {
		tab: {
			type: Object,
			required: true,
		},
	},

	components: {},
	computed: {
		...mapState(["active", "gig", "config", "band"]),

		isediting() {
			return this.active.isEditing;
		},
		tabname() {
			return this.active?.tab?.name || "";
		},
		groups() {
			var groups = this.active?.tab?.groups;

			if (!groups || groups.length < 1) {
				return null;
			} else {
				return groups;
			}
		},
		uploads() {
			var uploads = this.gig.uploads.filter((upload) => upload.tabid === this.tab.id).sort((a, b) => a.name.localeCompare(b.name)); // Sorteer op name (alfabetisch)

			// console.log(uploads);

			return uploads;
		},
	},

	methods: {
		download(upload) {
			console.log(upload);

			if (this.config.debug == 1) {
				console.log("downloadFile");
			}

			var post = {};
			post.task = "downloadFile";
			post.usertoken = this.active.usertoken;
			post.fileId = upload.id;

			// console.log(post);

			axios.post(this.config.apiserver + "uploads", post, {
				responseType: "blob", // Belangrijk: download binaire data (blob)
			})
				.then((response) => {
					// Log de headers om te controleren of alles klopt
					console.log(response.headers);

					// Maak een blob van de response data
					const url = window.URL.createObjectURL(new Blob([response.data]));

					const contentDisposition = response.request.getResponseHeader("content-disposition");

					let fileName = "downloaded_file"; // fallback naam

					if (contentDisposition) {
						const matches = contentDisposition.match(/filename="(.+)"/);
						if (matches && matches[1]) {
							fileName = matches[1];
						}
					}

					// Maak een link element
					const link = document.createElement("a");
					link.href = url;

					link.setAttribute("download", fileName);

					document.body.appendChild(link);

					link.click();

					// Verwijder de link na het downloaden
					link.remove();
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},

		verwijder(upload) {
			console.log(upload);

			if (this.config.debug == 1) {
				console.log("deleteUpload");
			}

			var post = {};
			post.task = "deleteUpload";
			post.usertoken = this.active.usertoken;
			post.uploadId = upload.id;

			console.log(post);

			axios.post(this.config.apiserver + "uploads", post)

				.then((response) => {
					if (this.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
	watch: {},
	mounted() {},
};
</script>

<style scoped>
.screen {
	height: calc(100dvh - 230px);
}

.down {
	fill: var(--grey-1) "";
}
</style>
