<template>
	<div class="hashnotok centercenter">
		<div class="debug">MaxNotok</div>

		<div class="centertext">
			<h2>Deze link is verlopen</h2>
			<br />
			<a href="/">Klik om een nieuwe inloglink te ontvangen</a>
		</div>
	</div>
</template>

<style scoped>
.hashnotok {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--blauw);
	color: #fff;
}
</style>
