<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandMembers'">
			<div class="debug">UserHomeBandsBandMembers</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon /></a>
				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<div class="section">
					<div class="text">
						<h1>{{ $translate("UserHomeBandsBandMembersTitle") }}</h1>
					</div>

					<div v-for="user in this.band.users" :key="user.id" class="status-container">
						<div class="button grey w100 mr16">
							<span v-if="user.name" class="mr8">
								{{ user.name }}
							</span>
							<span v-else class="mr8">
								{{ user.email }}
							</span>

							<span v-for="group in getgroups(user)" :key="group.id">
								<span class="badge">{{ group.group.name }}</span>
							</span>
						</div>

						<a class="button" @click="openpanel(user)">{{ $translate("EDIT") }}</a>
					</div>
				</div>

				<div class="section">
					<div class="text">
						<h2>{{ $translate("UserHomeBandsBandMembersInviteTitle") }}</h2>

						{{ $translate("UserHomeBandsBandMembersInviteText") }}
					</div>

					<table class="form">
						<tr>
							<td class="w100"><input v-model="email" type="text" placeholder="Email" /></td>
							<td class="tar ml16">
								<a class="button" @click="submit">{{ $translate("UserHomeBandsBandMembersSendEmail") }}</a>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";

// https://github.com/vueform/toggle

import {mapState} from "vuex";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";

export default {
	data() {
		return {
			email: "",
		};
	},

	components: {
		LeftIcon,
	},
	computed: {
		...mapState(["active", "config", "user", "band"]),
	},

	methods: {
		openpanel(user) {
			console.log(user);

			this.$setActiveProperty("member", user);
			this.$setActiveProperty("panel5", "UserHomeBandsBandMembersEdit");
		},

		getgroups(dezeuser) {
			var user = this.band.users.find((user) => user.id === dezeuser.id);
			var usergroups = user.user_groups;

			const filteredUsergroups = usergroups.filter((group) => group.band_id === this.band.id);

			return filteredUsergroups;
		},
		async submit() {
			var post = {};

			post.task = "addMusician";
			post.usertoken = this.active.usertoken;
			post.bandId = this.band.id;
			post.email = this.email;

			// console.log(post);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					this.$setActiveProperty("loading", false);

					console.log(response.data);

					this.email = "";

					this.$store.dispatch("fetchBands");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped>
.status-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.icon {
	width: 20px;
	height: 20px;
}
</style>
