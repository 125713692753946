<template>
	<transition name="slide-up">
		<div class="panel autoheight boxshadow" v-if="this.active.panel3 == 'UserHomeAccountLanguages'">
			<div class="debug">UserHomeAccountLanguages</div>

			<div class="header">
				<div class="title">Set language</div>
				<a class="right" @click="$setActiveProperty('panel3', '')"><DownIcon /></a>
			</div>

			<div class="content">
				<a @click="setlanguage('en')" class="button grey w100">English</a>
				<a @click="setlanguage('de')" class="button grey w100">Deutsch</a>
				<a @click="setlanguage('es')" class="button grey w100">Español</a>
				<a @click="setlanguage('fr')" class="button grey w100">Français</a>
				<a @click="setlanguage('nl')" class="button grey w100">Nederlands</a>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import DownIcon from "@/components/common/icons/DownIcon.vue";

export default {
	data() {
		return {
			status: "",
		};
	},

	components: {
		DownIcon,
	},

	methods: {
		setlanguage(language) {
			this.$setActiveSettings("language", language);

			this.$setActiveProperty("panel3", "");
		},
	},

	computed: {
		...mapState(["active", "bands", "config"]),
	},

	watch: {},
};
</script>

<style scoped></style>
