<template>
	<div class="screen">
		<h1>
			{{ $translate("GIGHOME_LOCATION") }}
			<span v-if="$userIsAdmin()">
				<span class="badge">iedereen</span>
			</span>
		</h1>

		<div :class="['formgroup', editing]">
			<div class="label">Venue</div>
			<div class="inputgroup">
				<input class="w100" v-model="localgig.venue" type="text" :disabled="!this.active.isEditing" />
			</div>
		</div>

		<div :class="['formgroup', editing]">
			<div class="label">City</div>
			<div class="inputgroup">
				<input class="w100" v-model="localgig.city" type="text" :disabled="!this.active.isEditing" />
			</div>
		</div>

		<div :class="['formgroup', editing]">
			<div class="label">Type</div>
			<div class="inputgroup" @click="$setActiveProperty('panel3', 'GigHomeCreateTypes')">
				<input v-model="type.name" type="text" disabled />
				<div class="chevron"></div>
			</div>
		</div>

		<div :class="['formgroup', editing]">
			<div class="label">Status</div>
			<div class="inputgroup">
				<input v-model="status.name" type="text" disabled />
				<div @click="$setActiveProperty('panel3', 'GigHomeCreateStatuses')" class="chevron"></div>
			</div>
		</div>

		<a class="button topright" v-if="!this.active.isEditing" @click="this.active.isEditing = true">{{ $translate("EDIT") }}</a>
		<a class="button bottomright" v-if="$userIsAdmin() && this.active.isEditing" @click="submit">{{ $translate("SAVE") }}</a>
	</div>
	<!-- screen -->
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {
			localgig: null,
		};
	},

	components: {},
	computed: {
		...mapState(["active", "gig", "band", "config"]),

		editing() {
			if (this.active.isEditing) {
				return "editing";
			} else {
				return "";
			}
		},

		status() {
			if (this.active.formStatus) {
				return this.active.formStatus;
			} else {
				return this.localgig.status;
			}
		},

		type() {
			if (this.active.formType) {
				return this.active.formType;
			} else {
				return this.localgig.type;
			}
		},
	},

	methods: {
		submit() {
			if (this.config.debug == 1) {
				console.log("updateGig");
			}

			this.active.isEditing = false;

			var post = {};

			post.task = "updateGig";
			post.usertoken = this.active.usertoken;
			post.venue = this.localgig.venue;
			post.city = this.localgig.city;
			post.gigId = this.gig.id;
			post.status_id = this.status.id;
			post.type_id = this.type.id;

			console.log(post);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "gigs", post)

				.then((response) => {
					console.log(response.data);

					this.$setActiveProperty("loading", false);
					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},

	watch: {
		"active.gigId": {
			handler() {
				// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
				this.localgig = JSON.parse(JSON.stringify(this.gig));
			},
			immediate: true,
		},
	},
};
</script>

<style scoped>
.screen {
	height: calc(100dvh - 205px);
}
</style>
