<template>
	<div class="debug">MaxAvailable</div>
	<div class="panel centercenter centertext">
		<div v-if="available.user.pivot.status == 'invited' || available.user.pivot.status == 'maybe'">
			<h1>Beste</h1>
			<h1>
				<div v-if="available.user.name">
					{{ available.user.name }}
				</div>

				<div v-else>
					{{ available.user.email }}
				</div>
			</h1>
			<h1>Kan je spelen</h1>
			<h1>{{ available.gig.venue }}</h1>
			<h1>{{ available.gig.city }}</h1>
			<h1>{{ available.gig.start }}</h1>
			<h1>met</h1>
			<h1>{{ available.band.name }}</h1>

			<a class="button" @click="this.$setActiveProperty('panel1', 'MaxAvailableYes')">{{ $translate("YES") }}</a>
			<br />
			<a class="button" @click="this.$setActiveProperty('panel1', 'MaxAvailableNo')">{{ $translate("NO") }}</a>
			<br />
			<a class="button" @click="this.$setActiveProperty('panel1', 'MaxAvailableMaybe')">{{ $translate("MAYBE") }}</a>
			<br />
		</div>

		<div v-if="available.user.pivot.status == 'yes' || available.user.pivot.status == 'no'">
			{{ $translate("AVAILABLE_GIVEN") }}
			<br />
			<br />

			<a class="button" @click="tomax()">{{ $translate("TOMAX") }}</a>
		</div>
	</div>
	<MaxAvailableYes />
	<MaxAvailableNo />
	<MaxAvailableMaybe />
</template>

<script>
import {mapState} from "vuex";
import MaxAvailableYes from "@/components/available/panels/MaxAvailableYes.vue";
import MaxAvailableNo from "@/components/available/panels/MaxAvailableNo.vue";
import MaxAvailableMaybe from "@/components/available/panels/MaxAvailableMaybe.vue";

export default {
	data() {
		return {};
	},
	components: {
		MaxAvailableYes,
		MaxAvailableNo,
		MaxAvailableMaybe,
	},
	computed: {
		...mapState(["config", "available"]),
	},

	methods: {
		tomax() {
			window.location.href = window.location.origin;
		},
	},
};
</script>

<style scoped></style>
