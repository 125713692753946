<template>
	<transition name="slide-up">
		<div class="panel autoheight" v-if="this.active.panel5 == 'UserHomeBandsBandTypesCreate'">
			<div class="debug">UserHomeBandsBandStatusesCreate</div>

			<div class="header">
				<div class="title">{{ $translate("UserHomeBandsBandTypesCreate") }}</div>
				<a class="left" @click="$setActiveProperty('panel5', '')"><DownIcon /></a>
			</div>

			<div class="content">
				<input class="mb16" v-model="type" type="text" placeholder="Name" />
				<a class="button fr" @click="submit">{{ $translate("UserHomeBandsBandTypesCreate") }}</a>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import DownIcon from "@/components/common/icons/DownIcon.vue";

export default {
	data() {
		return {
			type: "",
		};
	},

	components: {
		DownIcon,
	},

	methods: {
		async submit() {
			var post = {};

			post.task = "createType";
			post.usertoken = this.active.usertoken;
			post.name = this.type;
			post.bandId = this.active.bandId;

			axios.post(this.config.apiserver + "types", post)

				.then((response) => {
					console.log(response);

					// // Voer je bestaande logica uit
					this.$store.dispatch("fetchBands");
					this.$setActiveProperty("panel5", "");
					this.type = "";
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	computed: {
		...mapState(["active", "bands", "config", "translate"]),
	},

	watch: {},
};
</script>

<style scoped></style>
