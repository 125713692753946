<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel2 == 'UserHomeContacts'">
			<div class="debug">UserHomeContacts</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel2', '')"><LeftIcon /></a>

				<div class="title center">{{ $translate("USERHOMECONTACTS") }}</div>
			</div>

			<div class="content">
				<input v-model="search" type="text" :placeholder="$translate('SEARCH')" />

				<div v-for="contact in contacts" :key="contact.id" class="buttongroup">
					<div class="button grey w100 mr16">{{ contact.name }} {{ contact.email }} {{ contact.note }}</div>

					<a class="button" @click="deletecontact(contact)">{{ $translate("DELETE") }}</a>
				</div>

				<a class="button bottomright" @click="$setActiveProperty('panel3', 'UserHomeContactsCreate')">{{ $translate("USERHOMECONTACTS_NEWCONTACT") }}</a>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";

export default {
	data() {
		return {
			search: "",
		};
	},

	components: {
		LeftIcon,
	},
	computed: {
		...mapState(["active", "config", "user"]),

		contacts() {
			const contacts = Array.from(this.user.contacts);
			if (!this.search.trim()) return contacts;

			const result = contacts.filter((contact) => Object.values(contact).some((value) => String(value).toLowerCase().includes(this.search.toLowerCase())));

			return result;
		},
	},

	methods: {
		async deletecontact(contact) {
			var post = {};

			post.task = "deleteContact";
			post.usertoken = this.active.usertoken;
			post.contactId = contact.id;

			// console.log(post);

			axios.post(this.config.apiserver + "users", post)

				.then((response) => {
					console.log(response.data);
					this.$store.dispatch("fetchUser");
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped></style>
