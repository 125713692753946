import axios from "axios";
import store from "../store";

export default {
	fetchAvailable({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetch available");
		}

		const params = new URLSearchParams(window.location.search);
		const available = params.get("available");

		if (available) {
			const queryParams = new URLSearchParams({
				task: "checkAvailable",
				hash: available,
			});

			axios.get(`${store.state.config.apiserver}gigs?${queryParams.toString()}`)
				.then((response) => {
					commit("SET_AVAILABLE", response.data.data);

					commit("setActiveProperty", {property: "appstate", value: "available"});
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			commit("SET_AVAILABLE", null);
			commit("setActiveProperty", {property: "appstate", value: "calender"});
		}
	},

	checkLogin({commit}) {
		if (store.state.config.debug == 1) {
			console.log("check loginlink");
		}

		const params = new URLSearchParams(window.location.search);
		const loginlink = params.get("loginlink");

		if (loginlink) {
			var post = {};

			post.task = "check_loginlink";
			post.hash = loginlink;

			axios.post(store.state.config.apiserver + "login", post).then((response) => {
				if (response.data.status == "success") {
					if (store.state.config.debug == 1) {
						console.log("logged in by loginlink uit url");
					}

					sethashcookie(loginlink);

					commit("setActiveProperty", {property: "loginstate", value: "loginlinkOK"});
					commit("setActiveProperty", {property: "usertoken", value: response.data.data.usertoken});
					commit("setActiveProperty", {property: "loggedin", value: true});
					commit("UPDATE_FETCH_STATUS", {dataset: "login", result: "ok"});
				} else {
					commit("setActiveProperty", {property: "loginstate", value: "loginlinkNOTOK"});

					if (store.state.config.debug == 1) {
						console.log("Loginlink is not ok");
					}
				}
			});
		} else {
			const loginlink = gethashcookie();

			if (loginlink) {
				var post2 = {};

				post2.task = "check_loginlink";
				post2.hash = loginlink;

				axios.post(store.state.config.apiserver + "login", post2)
					.then((response) => {
						if (response.data.status == "success") {
							if (store.state.config.debug == 1) {
								console.log("logged in by loginlink uit cookie");
							}

							commit("setActiveProperty", {property: "loginstate", value: "loginlinkOK"});

							sethashcookie(loginlink);

							commit("setActiveProperty", {property: "usertoken", value: response.data.data.usertoken});
							commit("setActiveProperty", {property: "loggedin", value: true});

							commit("UPDATE_FETCH_STATUS", {dataset: "login", result: "ok"});

							// de loginlink weghalen;

							const url = new URL(window.location.href);
							if (url.searchParams.has("loginlink")) {
								url.searchParams.delete("loginlink");
								window.history.replaceState({}, document.title, url.toString());
							}
						} else {
							commit("setActiveProperty", {property: "loginstate", value: "loginlinkNOTOK"});

							if (this.config.debug == 1) {
								console.log("Hash is not ok");
							}
						}
					})
					.catch((error) => {
						console.error(error);

						if (this.config.debug == 1) {
							console.log("NOT logged in");
						}
					});
			}
		}
	},

	updateFetchStatus({commit}, dataset) {
		commit("UPDATE_FETCH_STATUS", {dataset: dataset, result: "ok"});
	},
	fetchGigChats({commit}, container) {
		if (store.state.config.debug == 1) {
			console.log("fetchGigChats");
		}

		// Ik bekijk welke chats er al geladen zijn.

		var highestId = 0;

		if (store.state.gig.id in store.state.gigchats) {
			var gigchats = store.state.gigchats[store.state.gig.id];

			highestId = gigchats.reduce((max, obj) => {
				return obj.id > max ? obj.id : max;
			}, 0);
		}

		const params = new URLSearchParams({
			task: "fetchGigChats",
			usertoken: store.state.active.usertoken,
			gigId: store.state.gig.id,
			startId: highestId,
		}).toString();

		console.log(params);

		commit("SET_ACTIVE_LOADING", true);

		axios.get(`${store.state.config.apiserver}gigchats?${params}`)

			.then((response) => {
				commit("SET_ACTIVE_LOADING", false);

				// console.log(response.data.data);

				var chats = response.data.data;

				chats = parseChats(chats);

				var gigId = store.state.gig.id;

				commit("SET_GIGCHATS", {gigId, chats: chats});

				// Scroll naar beneden na het ophalen van de chats
				if (container) {
					container.scrollTop = container.scrollHeight + 200;
				}
			})
			.catch((error) => {
				console.error(error);
			});
	},

	setBand({commit}) {
		if (store.state.config.debug == 1) {
			console.log("setBand");
		}

		var band = store.state.bands.find((obj) => obj.id === store.state.active.bandId);

		if (band) {
			commit("SET_BAND", band);
		} else {
			band = store.state.bands["0"];
			commit("SET_BAND", band);
		}
	},

	fetchBands({commit, dispatch}) {
		if (store.state.config.debug == 1) {
			console.log("fetchBands");
		}

		const params = new URLSearchParams({
			task: "fetchBands",
			usertoken: store.state.active.usertoken,
		}).toString();

		commit("SET_ACTIVE_LOADING", true);

		axios.get(`${store.state.config.apiserver}bands?${params}`)

			.then((response) => {
				commit("SET_ACTIVE_LOADING", false);

				const bands = response.data.data;

				// console.log(response.data);

				commit("SET_BANDS", bands);

				// De eerste band als default nemen voor de app
				dispatch("setBand");

				// Het filter standaard instellen voor deze bands

				const bandIds = bands.map((band) => band.id);

				commit("SET_BANDFILTER", bandIds);
			})
			.catch((error) => {
				console.error(error);
			});
	},

	fetchUser({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetchUser");
		}

		const params = new URLSearchParams({
			task: "fetchUser",
			usertoken: store.state.active.usertoken,
		}).toString();

		commit("SET_ACTIVE_LOADING", true);

		axios.get(`${store.state.config.apiserver}users?${params}`)

			.then((response) => {
				commit("SET_ACTIVE_LOADING", false);

				commit("UPDATE_FETCH_STATUS", {dataset: "user", result: "ok"});

				var user = response.data.data;

				//
				// DE SETTINGS OPSLAAN ALS EEN ARRAY VAN OBJECTEN
				// SETTINGS DIE NOG NIET BESTAAN AANMAKEN

				var settings = JSON.parse(user.settings);
				settings = Array.isArray(settings) ? settings : [settings];

				var index = settings.findIndex((item) => item.name === "theme");
				if (index === -1) {
					settings.push({name: "theme", value: "dark"});
				}

				index = settings.findIndex((item) => item.name === "language");
				if (index === -1) {
					settings.push({name: "language", value: "nl"});
				}

				user.settings = settings;

				commit("SET_USER", user);

				// Settings van de user toepassen

				var darktheme = settings.find((item) => item.name === "theme");

				if (darktheme.value === "dark") {
					document.body.classList.add("dark");
				} else {
					document.body.classList.remove("dark");
				}

				var language = settings.find((item) => item.name === "language");

				commit("SET_ACTIVE_LANGUAGE", language.value);

				// console.log(language);
			})
			.catch((error) => {
				console.error(error);
			});
	},

	fetchNotifications({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetchNotifications");
		}

		const params = new URLSearchParams({
			task: "fetchNotifications",
			usertoken: store.state.active.usertoken,
		}).toString();

		axios.get(`${store.state.config.apiserver}notifications?${params}`)

			.then((response) => {
				var notifications = response.data.data;

				// console.log(response.data);

				commit("SET_NOTIFICATIONS", notifications);
			})
			.catch((error) => {
				console.error(error);
			});
	},

	fetchTiers({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetchTiers");
		}

		const params = new URLSearchParams({
			task: "fetchTiers",
		}).toString();

		commit("SET_ACTIVE_LOADING", true);

		axios.get(`${store.state.config.apiserver}tiers?${params}`)

			.then((response) => {
				commit("SET_ACTIVE_LOADING", false);

				commit("UPDATE_FETCH_STATUS", {dataset: "tiers", result: "ok"});

				var tiers = response.data.data;

				// console.log(tiers);

				commit("SET_TIERS", tiers);
			})
			.catch((error) => {
				console.error(error);
			});
	},

	fetchGigs({commit, dispatch}) {
		if (store.state.config.debug == 1) {
			console.log("fetchGigs");
		}

		const params = new URLSearchParams({
			task: "fetchGigs",
			usertoken: store.state.active.usertoken,
		}).toString();

		commit("SET_ACTIVE_LOADING", true);

		axios.get(`${store.state.config.apiserver}gigs?${params}`)

			.then((response) => {
				commit("SET_ACTIVE_LOADING", false);

				var gigs = response.data.data;

				// console.log(response.data);

				var filteredBandIds = store.state.active.filterBandIds;

				if (filteredBandIds == 0) {
					gigs = filterGigsBands(gigs, filteredBandIds);
				}

				gigs = filterGigsByShared(gigs);

				commit("SET_ACTIVE_NUMBEROFFILTEREDGIGS", gigs.length);

				gigs = getmusicianstatus(gigs);

				// console.log(gigs);

				var gigsparsed = parseGigs(gigs);

				commit("SET_GIGS", gigsparsed);

				var gigsbyweek = parseGigsByWeek(gigs);
				commit("SET_GIGSBYWEEK", gigsbyweek);

				// console.log(response.data);

				gigs = parseGigsByDay(gigs);

				commit("SET_GIGSBYDAY", gigs);

				commit("UPDATE_FETCH_STATUS", {dataset: "gigs", result: "ok"});

				dispatch("setGig");
			})
			.catch((error) => {
				console.log("error fetchGigs");
				console.error(error);

				commit("UPDATE_FETCH_STATUS", {dataset: "gigs", result: "error"});
			});
	},

	setGig({commit, dispatch}) {
		if (store.state.config.debug == 1) {
			console.log("setGig");
		}

		var gigId = store.state.active.gigId;

		if (!gigId) {
			gigId = 1219;
		}

		var thisgig = store.state.gigs.find((element) => element.id === gigId);

		commit("SET_GIG", thisgig);

		if (thisgig && thisgig.band) {
			commit("SET_ACTIVE_BANDID", thisgig.band.id);

			dispatch("setBand");
		}
	},

	fetchTranslations({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetchTranslations");
		}

		commit("SET_ACTIVE_LOADING", true);

		axios.get(store.state.config.apiserver + "translations")

			.then((response) => {
				commit("SET_ACTIVE_LOADING", false);

				const translations = parseTranslations(response.data.data);

				commit("SET_TRANSLATIONS", translations);
				commit("UPDATE_FETCH_STATUS", {dataset: "translations", result: "ok"});
			})
			.catch((error) => {
				console.error(error);

				commit("UPDATE_FETCH_STATUS", {dataset: "translations", result: "error"});
			});
	},
};

function parseChats(chats) {
	const oneWeekAgo = new Date();
	oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

	return chats.map((chat) => {
		const date = new Date(chat.created_at);

		const optionsDayOfWeek = {weekday: "long"};
		const optionsDate = {year: "numeric", month: "2-digit", day: "2-digit"};
		const optionsTime = {hour: "2-digit", minute: "2-digit"};

		return {
			...chat,
			dateTime: date.toLocaleTimeString("nl-NL", optionsTime),
			dateDate: date > oneWeekAgo ? date.toLocaleDateString("nl-NL", optionsDayOfWeek) : date.toLocaleDateString("nl-NL", optionsDate),
		};
	});
}

function parseTranslations(translations) {
	const translationsByCode = {};

	translations.forEach((translation) => {
		const code = translation.code;

		translationsByCode[code] = translation;
	});

	return translationsByCode;
}

function getmusicianstatus(gigs) {
	gigs = gigs.map((gig) => {
		if (!gig.musicians || !Array.isArray(gig.musicians)) {
			return gig;
		}

		const invitedCount = gig.musicians.filter((user) => {
			if (!user.pivot || typeof user.pivot.status !== "string") {
				return false;
			}

			return user.pivot.status === "invited";
		}).length;

		const yesCount = gig.musicians.filter((user) => user.pivot.status === "yes").length;
		const noCount = gig.musicians.filter((user) => user.pivot.status === "no").length;
		const maybeCount = gig.musicians.filter((user) => user.pivot.status === "maybe").length;

		gig.musicianstatus = {
			invited: invitedCount,
			yes: yesCount,
			no: noCount,
			maybe: maybeCount,
		};

		return gig;
	});

	return gigs;
}

function filterGigsByShared(gigs) {
	if (store.state.active.filterShowShared == false) {
		return gigs.filter((gig) => {
			if (gig.shared === false) {
				return true;
			}
		});
	} else {
		return gigs;
	}
}

function filterGigsBands(gigs, filteredBandIds) {
	return gigs.filter((gig) => {
		return gig.ownersbands.some((band) => {
			return filteredBandIds.includes(band.id);
		});
	});
}

function parseGigs(gigs) {
	gigs.sort((a, b) => new Date(a.start) - new Date(b.start));

	const gigsparsed = [];

	gigs.forEach((originalGig) => {
		var lang = store.state.active.lang;

		const gig = {...originalGig};

		var date = new Date(originalGig.start);

		var formated = {};

		formated.year = date.getFullYear();
		formated.date = date.getDate();
		formated.dayNameShort = new Intl.DateTimeFormat(lang, {weekday: "short"}).format(date);
		formated.dayNameLong = new Intl.DateTimeFormat(lang, {weekday: "long"}).format(date);
		formated.monthNameShort = new Intl.DateTimeFormat(lang, {month: "short"}).format(date);
		formated.monthNameLong = new Intl.DateTimeFormat(lang, {month: "long"}).format(date);
		formated.time = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

		gig.dateStart = formated;

		date = new Date(originalGig.end);

		formated = {};

		formated.year = date.getFullYear();
		formated.date = date.getDate();
		formated.dayNameShort = new Intl.DateTimeFormat(lang, {weekday: "short"}).format(date);
		formated.dayNameLong = new Intl.DateTimeFormat(lang, {weekday: "long"}).format(date);
		formated.monthNameShort = new Intl.DateTimeFormat(lang, {month: "short"}).format(date);
		formated.monthNameLong = new Intl.DateTimeFormat(lang, {month: "long"}).format(date);
		formated.time = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

		gig.dateEnd = formated;

		// Aantal dagen uitrekenen

		const startDate = new Date(originalGig.start);
		const endDate = new Date(originalGig.end);

		var differenceInMilliseconds = endDate.getTime() - startDate.getTime();
		var differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

		differenceInDays = Math.floor(differenceInDays);

		gig.numberofdays = differenceInDays + 1;

		if (!gig?.checklists || gig.checklists === null) {
			if (gig.band) {
				const checklistArray = JSON.parse(gig.band.checklists);
				gig.openchecklistitems = checklistArray?.length;
			}
		} else {
			const checklistArray = JSON.parse(gig.checklists);

			const aantalFalse = checklistArray.filter((item) => item.value === false).length;

			gig.openchecklistitems = aantalFalse;
		}

		gigsparsed.push(gig);
	});

	return gigsparsed;
}

function parseGigsByDay(gigs) {
	const gigsByDay = {};

	gigs.forEach((originalGig) => {
		const startDate = new Date(originalGig.start);
		const endDate = new Date(originalGig.end);

		for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, "0");
			const day = date.getDate().toString().padStart(2, "0");

			const dayKey = `${year}-${month}-${day}`;

			if (!gigsByDay[dayKey]) {
				gigsByDay[dayKey] = [];
			}

			// Maak een kopie van de gig om aan te passen
			const gig = {...originalGig};

			// Formatteer de start- en eindtijd als "HH:MM"
			const startTime = `${startDate.getHours().toString().padStart(2, "0")}:${startDate.getMinutes().toString().padStart(2, "0")}`;
			const endTime = `${endDate.getHours().toString().padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`;

			// Voeg de start- en eindtijd toe aan de gig
			gig.starttime = startTime;
			gig.endtime = endTime;

			// Bepaal het dayType
			if (startDate.toDateString() === endDate.toDateString()) {
				gig.dayType = "singleDay";
			} else if (startDate.toDateString() === date.toDateString()) {
				gig.dayType = "first";
			} else if (endDate.toDateString() === date.toDateString()) {
				gig.dayType = "last";
			} else {
				gig.dayType = "middle";
			}

			gig.month = month;
			gig.day = day;
			gig.year = year;

			gigsByDay[dayKey].push(gig);
		}
	});

	return gigsByDay;
}

function parseGigsByWeek(gigs) {
	const gigsByWeek = {};

	gigs.forEach((originalGig) => {
		const startDate = new Date(originalGig.start);
		const endDate = new Date(originalGig.end);

		for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
			const year = date.getFullYear();
			const weekNumber = getWeekNumber(date);
			const weekKey = `${year}-W${weekNumber.toString().padStart(2, "0")}`;

			if (!gigsByWeek[weekKey]) {
				gigsByWeek[weekKey] = [];
			}

			const gigIndex = gigsByWeek[weekKey].findIndex((gig) => gig.id === originalGig.id);
			if (gigIndex === -1) {
				// Als de gig niet al in de week is, voeg het toe
				const gig = {...originalGig};
				const startTime = `${startDate.getHours().toString().padStart(2, "0")}:${startDate.getMinutes().toString().padStart(2, "0")}`;
				const endTime = `${endDate.getHours().toString().padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`;

				gig.starttime = startTime;
				gig.endtime = endTime;

				// Bepaal het dayType gebaseerd op de datum in vergelijking met de start- en einddatum
				if (startDate.toDateString() === endDate.toDateString()) {
					gig.dayType = "singleDay";
				} else if (startDate.toDateString() === date.toDateString()) {
					gig.dayType = "first";
				} else if (endDate.toDateString() === date.toDateString()) {
					gig.dayType = "last";
				} else {
					gig.dayType = "middle";
				}

				gigsByWeek[weekKey].push(gig);
			}
		}
	});

	return gigsByWeek;
}

function getWeekNumber(d) {
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}

function sethashcookie(hash) {
	const cookieName = "Max";
	const d = new Date();
	d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 dagen
	const expires = "expires=" + d.toUTCString();

	// Check of we in een localhost omgeving zijn
	const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

	// Pas de cookie-instellingen aan op basis van de omgeving
	if (isLocalhost) {
		document.cookie = `${cookieName}=${hash};${expires};path=/;SameSite=Lax`;
	} else {
		document.cookie = `${cookieName}=${hash};${expires};path=/;SameSite=Strict;Secure`;
	}
}

function gethashcookie() {
	const name = "Max";
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookieArray = decodedCookie.split(";");
	for (let i = 0; i < cookieArray.length; i++) {
		let [cookieName, cookieValue] = cookieArray[i].trim().split("=");
		if (cookieName === name) {
			return cookieValue;
		}
	}
	return "";
}
// function removehashcookie() {
// 	document.cookie = "Max=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
// }
