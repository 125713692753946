<template>
	<div id="footer" class="">
		<div class="icon-container">
			<div class="item" :class="{active: activeScreen === 1}" @click="gotoscreen(1)">
				<MonthIcon />
				<div>{{ $translate("FOOTER_MONTH") }}</div>
			</div>
			<div class="item" :class="{active: activeScreen === 2}" @click="gotoscreen(2)">
				<WeekIcon />
				<div>{{ $translate("FOOTER_WEEK") }}</div>
			</div>
			<div class="item" :class="{active: activeScreen === 3}" @click="gotoscreen(3)">
				<DayIcon />
				<div>{{ $translate("FOOTER_DAY") }}</div>
			</div>
			<div class="item" :class="{active: activeScreen === 4}" @click="gotoscreen(4)">
				<ListIcon />
				<div>{{ $translate("FOOTER_LIST") }}</div>
			</div>
			<div class="item" :class="{active: activeScreen === 5}" @click="gotoscreen(5)">
				<RequestsIcon />
				<span v-if="active.numberofrequests > 0" class="number">{{ active.numberofrequests }}</span>
				<div>{{ $translate("FOOTER_REQUESTS") }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";

import MonthIcon from "@/components/common/icons/MonthIcon.vue";
import WeekIcon from "@/components/common/icons/WeekIcon.vue";
import DayIcon from "@/components/common/icons/DayIcon.vue";
import ListIcon from "@/components/common/icons/ListIcon.vue";
import RequestsIcon from "@/components/common/icons/RequestIcon.vue";

export default {
	data() {
		return {};
	},

	components: {
		MonthIcon,
		WeekIcon,
		DayIcon,
		ListIcon,
		RequestsIcon,
	},
	computed: {
		...mapState(["active", "translate"]),

		activeScreen() {
			return this.active.CalenderScreen;
		},
	},

	methods: {
		gotoscreen(screen) {
			this.$setActiveProperty("panel1", "");
			this.$setActiveProperty("panel2", "");
			this.$setActiveProperty("panel3", "");
			this.$setActiveProperty("panel4", "");
			this.$setActiveProperty("gigId", 0);

			this.$setActiveProperty("gotoCalenderScreen", screen);
		},
	},

	watch: {},
};
</script>

<style scoped>
#footer {
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 56px;
	background: var(--grey-1);
	border-top-left-radius: var(--border-radius-big);
	border-top-right-radius: var(--border-radius-big);
	z-index: 10;
}

.icon {
	display: inline-block;
}

.icon-container {
	height: 100%;
	display: flex; /* Activeert flexbox voor deze container */
	justify-content: space-between; /* Verdeelt de ruimte tussen de items gelijkmatig */
	align-items: center; /* Centreert de items verticaal */
}

.item {
	flex: 1;
	text-align: center; /* Centreert de tekst (of het icoon) binnen elk icoon-element */
	font-size: 11px;
	color: var(--grey-3);
	cursor: pointer;
}

.item.active {
	color: var(--blauw);
}

.icon {
	margin-top: 5px;
	width: 24px;
	height: 24px;
	fill: var(--grey-3);
	margin-bottom: -10px;
}

.active .icon {
	fill: var(--blauw);
}

.number {
	background: var(--blauw);
	color: var(--grey-1);
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
	width: 12px;
	height: 12px;
	border-radius: 8px;
	display: inline-block;
	position: relative;
	top: -5px;
	left: -5px;
	right: 0;
}
</style>
