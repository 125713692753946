<template>
	<div v-if="active.appstate == 'calender'">
		<div v-if="active.loginstate == 'login'">
			<UserLogin />
		</div>

		<div v-if="active.loginstate == 'checkhash'">
			<MaxLoading />
		</div>

		<div v-if="active.loginstate == 'loginlinkNOTOK'">
			<MaxNotok />
		</div>

		<div v-if="active.loginstate == 'loginlinkOK' && dataloaded">
			<MaxMobile />
		</div>
	</div>

	<div v-if="active.appstate == 'available'">
		<MaxAvailable />
	</div>
</template>

<script>
import MaxMobile from "@/components/MaxMobile.vue";
import MaxAvailable from "@/components/available/MaxAvailable.vue";
import MaxLoading from "@/components/login/MaxLoading.vue";
import UserLogin from "@/components/login/UserLogin.vue";
import MaxNotok from "@/components/login/MaxNotok.vue";

// import axios from "axios";
import {mapState} from "vuex";

export default {
	data() {
		return {
			state: "login",
			isDesktop: false,
		};
	},

	components: {
		MaxMobile,
		UserLogin,
		MaxLoading,
		MaxNotok,
		MaxAvailable,
	},

	methods: {
		checklogin() {
			this.$store.dispatch("checkLogin");
			this.$store.dispatch("fetchAvailable");
		},
		checkScreenSize() {
			this.isDesktop = window.innerWidth >= 768; // 768px is de Bootstrap MD breakpoint
		},
	},

	computed: {
		...mapState(["fetched", "config", "active", "gigs"]),

		desktop() {
			// return this.isDesktop;

			return false;
		},

		dataloaded() {
			var fetchedOk = [];

			this.fetched.forEach((item) => {
				if (item.result == "ok") {
					fetchedOk.push(item.dataset);
				}
			});

			var dataloaded = true;

			this.config.fetchbeforestartup.forEach((item) => {
				if (!fetchedOk.includes(item)) {
					dataloaded = false;
				}
			});

			return dataloaded;
		},
		loggedinchanges() {
			return this.active.loggedin;
		},
		gigIdChanges() {
			return this.active.gigId;
		},
		bandIdChanges() {
			return this.active.bandId;
		},
		panel1Changes() {
			return this.active.panel1;
		},
		panel2Changes() {
			return this.active.panel2;
		},
		panel3Changes() {
			return this.active.panel3;
		},
		panel4Changes() {
			return this.active.panel4;
		},
	},

	watch: {
		loggedinchanges() {
			this.$store.dispatch("fetchUser");
			this.$store.dispatch("fetchTiers");
			this.$store.dispatch("fetchGigs");
			this.$store.dispatch("fetchBands");
			this.$store.dispatch("fetchTranslations");
			this.$store.dispatch("fetchNotifications");
		},
		gigIdChanges() {
			this.$store.dispatch("setGig");
		},
		bandIdChanges() {
			this.$store.dispatch("setBand");
		},
		panel3Changes() {
			this.$setActiveProperty("panel4", "");
		},
		panel2Changes() {
			this.$setActiveProperty("panel3", "");
		},
		panel1Changes() {
			this.$setActiveProperty("panel2", "");
		},
		panel4Changes() {
			this.$setActiveProperty("panel5", "");
		},
	},

	created() {
		if (this.config.debug == 1) {
			console.log("====================================================================");
		}

		this.checklogin();
		this.checkScreenSize(); // Initiële check van schermgrootte
		window.addEventListener("resize", this.checkScreenSize); // Luister naar resize events
	},

	beforeUnmount() {
		window.removeEventListener("resize", this.checkScreenSize); // Verwijder event listener
	},
};
</script>

<style>
.default {
	background: var(--blauw);
}
</style>
