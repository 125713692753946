<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandInfo'">
			<div class="debug">UserHomeBandsBandInfo</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon /></a>
				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<div class="text">
					<h1>{{ $translate("UserHomeBandsBandInfoTitle") }}</h1>
				</div>

				<div v-for="user in this.band.users" :key="user.id" class="status-container">
					<div class="button grey w100 mr16">
						<span v-if="user.name" class="mr8">
							{{ user.name }}
						</span>
						<span v-else class="mr8">
							{{ user.email }}
						</span>

						<span v-for="group in getgroups(user)" :key="group.id">
							<span class="badge">{{ group.group.name }}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import LeftIcon from "@/components/common/icons/LeftIcon.vue";

import {mapState} from "vuex";

export default {
	data() {
		return {};
	},

	components: {
		LeftIcon,
	},
	computed: {
		...mapState(["active", "translate", "band"]),
	},

	methods: {
		getgroups(dezeuser) {
			var user = this.band.users.find((user) => user.id === dezeuser.id);
			var usergroups = user.user_groups;

			const filteredUsergroups = usergroups.filter((group) => group.band_id === this.band.id);

			return filteredUsergroups;
		},
	},

	watch: {},
};
</script>

<style scoped></style>
