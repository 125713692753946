<template>
	<transition name="slide-up">
		<div class="panel autoheight boxshadow" v-if="this.active.panel5 == 'UserHomeBandsBandGroupsCreate'">
			<div class="debug">UserHomeBandsBandGroupsCreate</div>

			<div class="header">
				<div class="title">{{ $translate("NEWGROUP") }}</div>
				<a class="right" @click="$setActiveProperty('panel5', '')"><DownIcon /></a>
			</div>

			<div class="content">
				<table class="form">
					<tr>
						<td class="w100"><input v-model="group" type="text" :placeholder="$translate('NEWGROUP')" /></td>
						<td class="tar ml16">
							<a class="button" @click="submit">{{ $translate("SAVE") }}</a>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
import DownIcon from "@/components/common/icons/DownIcon.vue";
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {
			group: null,
		};
	},

	components: {
		DownIcon,
	},
	computed: {
		...mapState(["active", "translate", "config"]),
	},

	methods: {
		async submit() {
			var post = {};

			post.task = "createGroup";
			post.usertoken = this.active.usertoken;
			post.name = this.group;
			post.bandId = this.active.bandId;

			axios.post(this.config.apiserver + "groups", post)

				.then(() => {
					this.$store.dispatch("fetchBands");
					this.$setActiveProperty("panel5", "");
					this.group = "";
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},

	watch: {},
};
</script>

<style scoped></style>
