<template>
	<transition name="slide-right" v-if="band">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandStatuses'">
			<div class="debug">UserHomeBandsBandStatuses</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon /></a>

				<div class="title center">{{ band.name }}</div>
			</div>

			<div class="content">
				<div class="section">
					<div class="text">
						<h2>{{ $translate("UserHomeBandsBandStatusesTitle") }}</h2>

						{{ $translate("UserHomeBandsBandStatusesText") }}
					</div>

					<div v-for="status in band.statuses" :key="status.id" class="status-container">
						<div class="button grey w100 mr16">
							{{ status.name }}
						</div>

						<a class="button" @click="statusClickHandler(status)">{{ $translate("DELETE") }}</a>
					</div>
				</div>

				<div class="button bottomright" @click="$setActiveProperty('panel5', 'UserHomeBandsBandStatusesCreate')">{{ $translate("UserHomeBandsBandStatusesCreate") }}</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";
import LeftIcon from "@/components/common/icons/LeftIcon.vue";

export default {
	data() {
		return {};
	},

	components: {
		LeftIcon,
	},
	computed: {
		...mapState(["active", "translate", "band"]),
	},

	methods: {
		statusClickHandler(status) {
			this.$setActiveProperty("status", status);
			this.$setActiveProperty("panel5", "UserHomeBandsBandStatusesDelete");
		},
	},

	watch: {},
};
</script>

<style scoped>
.status-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.button.ghost {
	flex-grow: 1;
	margin-right: 8px;
}
</style>
