<template>
	<div class="screen">
		<h1>
			{{ $translate("GIGHOME_PRIVATENOTE") }}
			<span v-if="$userIsAdmin()">
				<span class="badge">{{ $translate("YOU") }}</span>
			</span>
		</h1>
		<quill-editor ref="quillEditor" @ready="onEditorReady" />

		<a class="button topright" v-if="!this.active.isEditing" @click="this.$setActiveProperty('isEditing', true)">{{ $translate("EDIT") }}</a>

		<a class="button bottomright" v-if="this.active.isEditing" @click="submit">{{ $translate("SAVE") }}</a>
	</div>
</template>

<script>
import {QuillEditor} from "@vueup/vue-quill";
import {mapState} from "vuex";
import axios from "axios";

export default {
	data() {
		return {};
	},

	components: {QuillEditor},
	computed: {
		...mapState(["active", "gig", "config", "band"]),

		isediting() {
			return this.active.isEditing;
		},
	},

	methods: {
		onEditorReady(editor) {
			this.quill = editor;
		},
		submit() {
			if (this.config.debug == 1) {
				console.log("updatePrivateNote");
			}

			this.$setActiveProperty("isEditing", false);

			const delta = this.quill.getContents();

			var post = {};

			post.task = "updatePrivateNote";
			post.usertoken = this.active.usertoken;
			post.gigId = this.gig.id;
			post.privatenote = JSON.stringify(delta);

			this.$setActiveProperty("loading", true);

			axios.post(this.config.apiserver + "gigs", post)

				.then(() => {
					this.$setActiveProperty("loading", false);
					this.$store.dispatch("fetchGigs");
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		},
	},
	watch: {
		isediting() {
			if (this.active.isEditing == true) {
				this.quill.enable(true);
				this.quill.getModule("toolbar").container.style.display = "block";
			} else {
				this.quill.enable(false);
				this.quill.getModule("toolbar").container.style.display = "none";
			}
		},
	},
	mounted() {
		if (this.gig && this.gig.privatenote && this.gig.privatenote.note) {
			const delta = JSON.parse(this.gig.privatenote.note); // Probeer de JSON te parsen
			this.quill.setContents(delta); // Stel de Delta-inhoud in op de Quill-editor
		} else {
			this.quill.setContents([]); // Stel de editor in op een lege inhoud
		}

		this.quill.enable(false);
		this.quill.getModule("toolbar").container.style.display = "none";
	},
};
</script>

<style scoped>
.screen {
	height: calc(100dvh - 230px);
}

.down {
	fill: var(--grey-1) "";
}
</style>
