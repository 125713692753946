<template>
	<div id="header" class="">
		<a class="filter button" @click="$setActiveProperty('panel1', 'FilterHome')">Filter</a>

		<a class="notifications" @click="toggleNotificationsPanel()"><NotificationsIcon /></a>

		<a class="settings" @click="toggleUserPanels()">{{ initials }}</a>
	</div>
</template>

<script>
import {mapState} from "vuex";
import NotificationsIcon from "@/components/common/icons/NotificationsIcon.vue";

export default {
	data() {
		return {};
	},

	components: {NotificationsIcon},
	computed: {
		...mapState(["user", "active"]),

		initials() {
			if (this.user.name) {
				const words = this.user.name.split(" ");
				const initials = words[0][0].toUpperCase() + words[words.length - 1][0].toUpperCase();
				return initials;
			} else {
				return "";
			}
		},
	},

	methods: {
		toggleNotificationsPanel() {
			if (this.active.toppanel == "NotificationsPanel") {
				this.$setActiveProperty("toppanel", "");
			} else {
				this.$setActiveProperty("toppanel", "NotificationsPanel");
			}
		},
		toggleUserPanels() {
			if (this.active.toppanel == "UserHome") {
				this.$setActiveProperty("toppanel", "");
			} else {
				this.$setActiveProperty("toppanel", "UserHome");
			}
		},
	},

	watch: {},
};
</script>
<style scoped>
.filter {
	position: fixed;
	top: 8px;
	left: 8px;
}

.settings {
	position: fixed;
	right: 8px;
	top: 6px;
	background: var(--blauw);
	color: #fff;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	padding-top: 8px;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 10;
}

.notifications .icon.notifications {
	padding: 8px;
	position: fixed;
	right: 60px;
	top: 0px;
	width: 35px;
	height: 35px;
	stroke: #ffffff !important; /* Omtrek wit maken */
	fill: none !important; /* Midden transparant maken */
	cursor: pointer;
}

.icon.notifications {
}
</style>
